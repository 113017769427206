// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/5.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
}
.bg-img{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-height: auto;
}
.container{
  position: absolute;
  text-align: center;
  justify-content: center;
  width: auto;
  max-width: 65vh;
}
.PhoneForm{
  display: flex;
  flex-direction: row;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/static/css/Login.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,yBAAiB;UAAjB,iBAAiB;AACnB;AACA;EACE,yDAAqC;EACrC,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500&display=swap');\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  user-select: none;\n}\n.bg-img{\n  background-image: url('../img/5.png');\n  background-size: 100vh;\n  background-repeat: no-repeat;\n  background-position: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n  max-height: auto;\n}\n.container{\n  position: absolute;\n  text-align: center;\n  justify-content: center;\n  width: auto;\n  max-width: 65vh;\n}\n.PhoneForm{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
