import { React, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Sidebar from "../../components/Admin/Sidebar";
import "../../static/css/InformacionAtletas.css";
import jwtDecode from "jwt-decode";
import useAuth from "../../hooks/useAuth";
import SidebarSede from "../../components/Sede/SidebarSede";

const InformacionAtletas = () => {
  const [atletas, setAtletas] = useState();
  const axiosPrivate = useAxiosPrivate();
  const options = [
    { value: "", text: "Nivel" },
    { value: "", text: "--Gimnasia Artística--" },
    { value: "Baby Gym", text: "Baby Gym" },
    { value: "Little Gym", text: "Little Gym" },
    { value: "Little Star", text: "Little Star" },
    { value: "Beginner", text: "Beginner" },
    { value: "1", text: "Nivel 1" },
    { value: "2", text: "Nivel 2" },
    { value: "3", text: "Nivel 3" },
    { value: "4", text: "Nivel 4" },
    { value: "5", text: "Nivel 5" },
    { value: "6", text: "Nivel 6" },
    { value: "7", text: "Nivel 7" },
    { value: "ExcelBronce", text: "Excel Bronce" },
    { value: "ExcelSilver", text: "Excel Silver" },
    { value: "ExcelGold", text: "Excel Gold" },
    { value: "", text: "--Gimnasia en Trampolin--" },
    { value: "Primer Año", text: "Primer Año" },
    { value: "Segundo Año", text: "Segundo Año" },
    { value: "Tercer Año", text: "Tercer Año" },
    { value: "Cuarto Año", text: "Cuarto Año" },
    { value: "Quinto Año", text: "Quinto Año" },
    { value: "Sexto Año", text: "Sexto Año" },
    { value: "Séptimo Año", text: "Séptimo Año" },
    { value: "Octavo Año", text: "Octavo Año" },
    { value: "Noveno Año", text: "Noveno Año" },
    { value: "Décimo Año", text: "Décimo Año" },
    { value: "Undécimo Año", text: "Undécimo Año" },
    { value: "Dudécimo Año", text: "Duodécimo Año" },
    { value: "Duodécimo Año", text: "Duodécimo Año" },
    { value: "Décimotercer Año", text: "Décimotercer Año" },
  ];

  const [selected, setSelected] = useState(options[0].value);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selection, setSelection] = useState([]);
  const [search, setSearch] = useState("");
  const { auth } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/api/atleta/info/", {
          signal: controller.signal,
        });
        isMounted && setAtletas(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    getUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate]);

  useEffect(() => {
    if (atletas) {
      let filtered = atletas.filter((atleta) => {
        const fullName = (atleta.Nombre + " " + atleta.Apellido1).toLowerCase();
        return (
          fullName.includes(search.toLowerCase()) && atleta.Nivel === selected
        );
      });
    }
  }, [search, atletas, selected]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
    let tempArray = [];

    if (value === "") {
      setSelection([]);
      setIsLoaded(false);
    } else {
      for (let i = 0; i < atletas.length; i++) {
        const atleta = atletas[i];
        if (atleta.Nivel === value) {
          tempArray.push(atleta);
        }
      }
      setSelection(tempArray);
      setIsLoaded(true);
    }
    setSearch("");
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearch(value);

    if (value === "") {
      const tempArray = atletas.filter((atleta) => atleta.Nivel === selected);
      setSelection(tempArray);
    } else {
      const tempArray = atletas.filter((atleta) => {
        const fullName = (atleta.Nombre + " " + atleta.Apellido1).toLowerCase();
        return (
          fullName.includes(search.toLowerCase()) && atleta.Nivel === selected
        );
      });
      setSelection(tempArray);
    }
  };

  const filteredSelection = selection.filter((atleta) =>
    (atleta.Nombre + " " + atleta.Apellido1)
      .toLowerCase()
      .includes(search.toLowerCase())
  );
  return (
    <section className="InformacionAtletas">
      {jwtDecode(auth.accessToken).role === 1200 ? (
        <SidebarSede />
      ) : (
        <Sidebar />
      )}
      <div className="AtletasContent">
        <div className="Selector">
          Seleccione un nivel:
          <select value={selected} onChange={handleChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar atleta"
            value={search}
            onChange={handleSearch}
          />
        </div>
        <div className="tablas">
          <table>
            <thead>
              <th>Número de Gimnasta</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Fecha de Nacimiento</th>
              <th>Nivel</th>
            </thead>
            <tbody>
              {isLoaded && filteredSelection.length >= 1 ? (
                filteredSelection.map((atleta) => (
                  <tr key={atleta.id}>
                    <td>{atleta.NumSocio}</td>
                    <td>{atleta.Nombre}</td>
                    <td>{atleta.Apellido1}</td>
                    <td>{atleta.DOB}</td>
                    <td>{atleta.Nivel}</td>
                    <td>
                      <a href={`/admin/info-atletas/${atleta.NumSocio}`}>
                        <button className="button">Seleccionar</button>
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <p>No hay atletas disponibles</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};
export default InformacionAtletas;
