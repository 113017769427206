// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calificaciones{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Elementos{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3vh;
    margin-bottom: 3vh;
}
.Categoria{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.TituloGrupo{
    margin-top: 5vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5vh;
}
.Elemento{
    margin-left: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 4vh;
}
.TituloElemento{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.CalificacionesInput{
    text-align: center;
    margin-left: 2vh;
    height: 4vh;
    width: 4vh;
}
.break {
    flex-basis: 100%;
}
.Rutina{
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/static/css/Nivel4.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,uBAAuB;IACvB,QAAQ;IACR,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,UAAU;AACd;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".Calificaciones{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.Elementos{\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 3vh;\n    margin-bottom: 3vh;\n}\n.Categoria{\n    margin-left: auto;\n    margin-right: auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.TituloGrupo{\n    margin-top: 5vh;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    margin-bottom: 5vh;\n}\n.Elemento{\n    margin-left: 4vh;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-right: 4vh;\n}\n.TituloElemento{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 0;\n}\n.CalificacionesInput{\n    text-align: center;\n    margin-left: 2vh;\n    height: 4vh;\n    width: 4vh;\n}\n.break {\n    flex-basis: 100%;\n}\n.Rutina{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
