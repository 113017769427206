import React, { useState, useEffect } from 'react';
import "../../static/css/Nivel4.css"
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth'

const NivelExcelGold = (props) => {
    const { data } = props;
	const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [SaltoElemento1, setSaltoElemento1] = useState("");
    const [SaltoElemento2, setSaltoElemento2] = useState("");
    const [SaltoRutina, setSaltoRutina] = useState("");
    const [BarraElemento1, setBarraElemento1] = useState("");
    const [BarraElemento2, setBarraElemento2] = useState("");
    const [BarraElemento3, setBarraElemento3] = useState("");
    const [BarraElemento4, setBarraElemento4] = useState("");
    const [BarraElemento5, setBarraElemento5] = useState("");
    const [BarraElemento6, setBarraElemento6] = useState("");
    const [BarraRutina, setBarraRutina] = useState("");
    const [VigaElemento1, setVigaElemento1] = useState("");
    const [VigaElemento2, setVigaElemento2] = useState("");
    const [VigaElemento3, setVigaElemento3] = useState("");
    const [VigaElemento4, setVigaElemento4] = useState("");
    const [VigaElemento5, setVigaElemento5] = useState("");
    const [VigaElemento6, setVigaElemento6] = useState("");
    const [VigaRutina, setVigaRutina] = useState("");
    const [PisoElemento1, setPisoElemento1] = useState("");
    const [PisoElemento2, setPisoElemento2] = useState("");
    const [PisoElemento3, setPisoElemento3] = useState("");
    const [PisoElemento4, setPisoElemento4] = useState("");
    const [PisoElemento5, setPisoElemento5] = useState("");
    const [PisoElemento6, setPisoElemento6] = useState("");
    const [PisoRutina, setPisoRutina] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(
            `/api/atleta/info/nivelexcelgold/`,
            JSON.stringify({
                NumSocio: data.NumSocio,
                SaltoElemento1 : SaltoElemento1,
                SaltoElemento2 : SaltoElemento2,
                SaltoRutina : SaltoRutina,
                BarraElemento1 : BarraElemento1,
                BarraElemento2 : BarraElemento2,
                BarraElemento3 : BarraElemento3,
                BarraElemento4 : BarraElemento4,
                BarraElemento5 : BarraElemento5,
                BarraElemento6 : BarraElemento6,
                BarraRutina : BarraRutina,
                VigaElemento1 : VigaElemento1,
                VigaElemento2 : VigaElemento2,
                VigaElemento3 : VigaElemento3,
                VigaElemento4 : VigaElemento4,
                VigaElemento5 : VigaElemento5,
                VigaElemento6 : VigaElemento6,
                VigaRutina : VigaRutina,
                PisoElemento1 : PisoElemento1,
                PisoElemento2 : PisoElemento2,
                PisoElemento3 : PisoElemento3,
                PisoElemento4 : PisoElemento4,
                PisoElemento5 : PisoElemento5,
                PisoElemento6 : PisoElemento6,
                PisoRutina : PisoRutina,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${auth.accessToken}`,
              },
            }
          );
          alert('Informacion actualizada correctamente!');
          navigate(-1);
        } catch (err) {
          console.log(err);
        }
      };
    return(
        <section className="Nivel4">
            <h3 className="Categoria">Categoria: Excel Gold</h3>
            <form onSubmit={handleSubmit} className="Calificaciones">
                <h4 className="TituloGrupo">Salto</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1:  CARRERA DE APROXIMACION AL TRAMPOLIN</h4>
                        <input name='Elemento1Salto' type='text' className='CalificacionesInput' value={SaltoElemento1} onChange={(e) => setSaltoElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: SALTO NIVEL GOLD</h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={SaltoElemento2} onChange={(e) => setSaltoElemento2(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4>Rutina: </h4>
                        <input name='RutinaSalto' type='text' className='CalificacionesInput' value={SaltoRutina} onChange={(e) => setSaltoRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Barra</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: SUBIDA A FUERZA/KIPE</h4>
                        <input name='Elemento1Barra' type='text' className='CalificacionesInput' value={BarraElemento1} onChange={(e) => setBarraElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: VOLTERETA ADELANTE/DESPEGUE A 45 °</h4>
                        <input name='Elemento2Barra' type='text' className='CalificacionesInput' value={BarraElemento2} onChange={(e) => setBarraElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: DESPEGUE 45 ° / VUELTA ATRÁS / 1/2 MOLINO</h4>
                        <input name='Elemento3Barra' type='text' className='CalificacionesInput' value={BarraElemento3} onChange={(e) => setBarraElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: VOLTERETA ATRÁS / DESPEGUE A 45 ° / POLLITO</h4>
                        <input name='Elemento4Barra' type='text' className='CalificacionesInput' value={BarraElemento4} onChange={(e) => setBarraElemento4(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: KIPE  / VOLTERETA</h4>
                        <input name='Elemento5Barra' type='text' className='CalificacionesInput' value={BarraElemento5} onChange={(e) => setBarraElemento5(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 6: SALIDA FLECHA / SALIDA 1/2 GIRO / SALIDA MORTAL</h4>
                        <input name='Elemento6Barra' type='text' className='CalificacionesInput' value={BarraElemento6} onChange={(e) => setBarraElemento6(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaBarra' type='text' className='CalificacionesInput' value={BarraRutina} onChange={(e) => setBarraRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Viga</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: ENTRADA A</h4>
                        <input name='Elemento1Viga' type='text' className='CalificacionesInput' value={VigaElemento1} onChange={(e) => setVigaElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: GIRO COMPLETO </h4>
                        <input name='Elemento2Viga' type='text' className='CalificacionesInput' value={VigaElemento2} onChange={(e) => setVigaElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: ZANCADA 120°/SALTO SPLIT 120°</h4>
                        <input name='Elemento3Viga' type='text' className='CalificacionesInput' value={VigaElemento3} onChange={(e) => setVigaElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: 1/2 LUNA / ARCO ATRÁS / PARADA DE MANOS</h4>
                        <input name='Elemento4Viga' type='text' className='CalificacionesInput' value={VigaElemento4} onChange={(e) => setVigaElemento4(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: 1/2 LUNA   ARCO ATRÁS.   P.MANOS </h4>
                        <input name='Elemento5Viga' type='text' className='CalificacionesInput' value={VigaElemento5} onChange={(e) => setVigaElemento5(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 6: SALIDA NIVEL 3 O MORTAL </h4>
                        <input name='Elemento5Viga' type='text' className='CalificacionesInput' value={VigaElemento6} onChange={(e) => setVigaElemento6(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaViga' type='text' className='CalificacionesInput' value={VigaRutina} onChange={(e) => setVigaRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Piso</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: RONDADA FLIC / RONDADA MORAL / RESORTE ADELANTE + 1/2 LUNA</h4>
                        <input name='Elemento1Piso' type='text' className='CalificacionesInput' value={PisoElemento1} onChange={(e) => setPisoElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: GIRO COMPLETO SOBRE UN PIE</h4>
                        <input name='Elemento2Piso' type='text' className='CalificacionesInput' value={PisoElemento2} onChange={(e) => setPisoElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: ZANCADA A 90° + SISÓN</h4>
                        <input name='Elemento3Piso' type='text' className='CalificacionesInput' value={PisoElemento3} onChange={(e) => setPisoElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: 1/2 LUNA + RONDADA / RONDADA FLIC / RESORTE ADELANTE + 1/2 LUNA</h4>
                        <input name='Elemento4Piso' type='text' className='CalificacionesInput' value={PisoElemento4} onChange={(e) => setPisoElemento4(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: MORTAL ADELANTE</h4>
                        <input name='Elemento5Piso' type='text' className='CalificacionesInput' value={PisoElemento5} onChange={(e) => setPisoElemento5(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 6: COREOGRAFIA</h4>
                        <input name='Elemento5Piso' type='text' className='CalificacionesInput' value={PisoElemento6} onChange={(e) => setPisoElemento6(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaPiso' type='text' className='CalificacionesInput' value={PisoRutina} onChange={(e) => setPisoRutina(e.target.value)}/>
                    </div>
                </div>
                <input type="submit" className="button" value="Actualizar" />
            </form>
        </section>
    )
}

export default NivelExcelGold;