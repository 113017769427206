// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoDetailed{
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.InfoEditContent{
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EditForm{
    display: flex;
    flex-direction: column;
}
.text-field{
    margin-bottom: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/static/css/InfoDetailed.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".InfoDetailed{\n    display: flex;\n    flex-direction: row;\n    height: 100vh;\n}\n.InfoEditContent{\n    margin-top: 5vh;\n    margin-left: auto;\n    margin-right: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.EditForm{\n    display: flex;\n    flex-direction: column;\n}\n.text-field{\n    margin-bottom: 3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
