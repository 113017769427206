import axios from '../utils/axios';
import useAuth from './useAuth';
import jwtDecode from 'jwt-decode';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.post('/auth/token/refresh/', {
            refresh: localStorage.getItem('refreshToken')
        });
        setAuth(prev =>{
            return { 
                ...prev,
                roles: [jwtDecode(response.data.refresh).role],
                accessToken: response.data.access}
        });
        return response.data.access_token;
    }
    return refresh
}

export default useRefreshToken;