export function Split(x){
    if(x < 2){
        return 0;
    }else if(x < 4){
        return 1;
    }else if(x < 6){
        return 2;
    }else if(x < 10){
        return 4;
    }else if(x === 10){
        return 5;
    }else if(x < 15){
        return 6;
    }else if(x < 20){
        return 7;
    }else if(x < 25){
        return 8;
    }
    else if(x < 30){
        return 9;
    }else{
        return 10;
    }
}

export function Flexion(x){
    if(x < 0){
        return 0;
    }else if(x < 2){
        return 1;
    }else if(x < 3){
        return 2;
    }else if(x < 4){
        return 3;
    }else if(x < 5){
        return 4;
    }else if(x < 6){
        return 5;
    }else if(x < 8){
        return 6;
    }else if(x < 10){
        return 7;
    }else if(x < 12){
        return 8;
    }else if(x < 14){
        return 9;
    }else{
        return 10;
    }
}

export function SaltoHorizontal(x){
    if (x < 0){
        return 0;
    }else if(x < 5){
        return 1;
    }else if(x < 10){
        return 2;
    }else if(x < 15){
        return 3;
    }else if(x < 20){
        return 4;
    }else if(x < 25){
        return 5;
    }else if(x < 30){
        return 6;
    }else if(x < 35){
        return 7;
    }else if(x < 40){
        return 8;
    }else if(x < 45){
        return 9;
    }else{
        return 10;
    }
}

export function Velocidad(x){
    if(x > 4.5){
        return 0;
    }else if(x > 4.4){
        return 1;
    }else if(x > 4.3){
        return 2;
    }else if(x > 4.2){
        return 3;
    }else if(x > 4.1){
        return 4;
    }else if(x > 4.0){
        return 5;
    }else if(x > 3.9){
        return 6;
    }else if(x > 3.8){
        return 7;
    }else if(x > 3.7){
        return 8;
    }else if(x > 3.6){
        return 9;
    }else{
        return 10;
    }
}

export function ElevacionPiernas(x){
    if(x < 6){
        return 0;
    }else if(x < 7){
        return 1;
    }else if(x < 8){
        return 2;
    }else if(x < 9){
        return 3;
    }else if(x < 10){
        return 4;
    }else if(x < 11){
        return 5;
    }else if(x < 12){
        return 6;
    }else if(x < 13){
        return 7;
    }else if(x < 14){
        return 8;
    }else if(x < 15){
        return 9;
    }else{
        return 10;
    }
}

export function ElevacionTronco(x){
    if(x < 20){
        return 0;
    }else if (x < 22){
        return 1;
    }else if (x < 24){
        return 2;
    }else if (x < 26){
        return 3;
    }else if (x < 28){
        return 4;
    }else if (x < 30){
        return 5;
    }else if (x < 31){
        return 6;
    }else if (x < 32){
        return 7;
    }else if (x < 33){
        return 8;
    }else if (x < 34){
        return 9;
    }else{
        return 10;
    }
}

export function Barbilla(x){
    if(x < 3){
        return 0;
    }else if(x < 5){
        return 1;
    }else if(x < 7){
        return 2;
    }else if(x < 9){
        return 3;
    }else if(x < 11){
        return 4;
    }else if(x < 13){
        return 5;
    }else if(x < 15){
        return 6;
    }else if(x < 17){
        return 7;
    }else if(x < 19){
        return 8;
    }else if(x < 20){
        return 9;
    }else{
        return 10;
    }

}

export function Fondos(x){
    if(x < 3){
        return 0;
    }else if(x < 5){
        return 1;
    }else if(x < 7){
        return 2;
    }else if(x < 9){
        return 3;
    }else if(x < 11){
        return 4;
    }else if(x < 13){
        return 5;
    }else if(x < 15){
        return 6;
    }else if(x < 17){
        return 7;
    }else if(x < 19){
        return 8;
    }else if(x < 20){
        return 9;
    }else{
        return 10;
    }
}

export function Sostener(x){
    if(x < 2){
        return 0;
    }else if(x < 5){
        return 1;
    }else if(x < 10){
        return 2;
    }else if(x < 20){
        return 3;
    }else if(x < 30){
        return 4;
    }else if(x < 40){
        return 5;
    }else if(x < 45){
        return 6;
    }else if(x < 50){
        return 7;
    }else if(x < 55){
        return 8;
    }else if(x < 60){
        return 9;
    }else{
        return 10;
    }
}

export function SaltoVertical(x){
    if (x < 25){
        return 0;
    }else if(x < 30){
        return 1;
    }else if(x < 35){
        return 2;
    }else if(x < 40){
        return 3;
    }else if(x < 45){
        return 4;
    }else if(x < 50){
        return 5;
    }else if(x < 55){
        return 6;
    }else if(x < 60){
        return 7;
    }else if(x < 65){
        return 8;
    }else if(x < 70){
        return 9;
    }else{
        return 10;
    }
}
