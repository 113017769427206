import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import jwtDecode from "jwt-decode";
import useAuth from "../../hooks/useAuth";
import NivelesBajosTecnico from "../../components/Atletas/NivelesBajosTecnico";
import Nivel4Tecnico from "../../components/Atletas/Nivel4Tecnico";
import NivelExcelBronceTecnico from "../../components/Atletas/NivelExcelBronceTecnico";
import NivelExcelSilverTecnico from "../../components/Atletas/NivelExcelSilverTecnico";
import NivelExcelGoldTecnico from "../../components/Atletas/NivelExcelGoldTecnico";


const InformeTecnico = () => {
    const NivelesBajos = ["Baby Gym", "Little Gym", "Little Star", "Beginner", "1", "2", "3"]
    const { auth } = useAuth();
    const param = useParams();
	const axiosPrivate = useAxiosPrivate();
    const [NumSocio, setNumSocio] = useState("");
    const [Nivel, setNivel] = useState();
    useEffect(() => {
        const decodedAccessToken = jwtDecode(auth.accessToken);
        const newNumSocio = decodedAccessToken.NumSocio || param.NumSocio;
        setNumSocio(newNumSocio);
      }, [auth.accessToken, param.NumSocio]);

    useEffect(() => {
    
    const getUser = async () => {
        try {
          const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}/`);
          setNivel(response.data.data.Nivel);
        } catch (err) {
        }
      };
    if(NumSocio){
        getUser();
    }
    }, [NumSocio, axiosPrivate])
    
    return(
        <section className="InformeTecnico">
        {
            NivelesBajos.includes(Nivel)
            ?<NivelesBajosTecnico />
            :Nivel === "4"
                ?<Nivel4Tecnico />
                : Nivel === "ExcelBronce"
                    ?<NivelExcelBronceTecnico />
                    :Nivel === "ExcelSilver"
                        ?<NivelExcelSilverTecnico />
                        :Nivel === "ExcelGold"
                            ?<NivelExcelGoldTecnico />
                            :<h1>Loading...</h1>

            }

        </section>
    )
}

export default InformeTecnico