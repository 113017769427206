import { useEffect, useState } from "react";
import Sidebar from "../../components/Admin/Sidebar";
import "../../static/css/HomeAdmin.css"
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import SidebarSede from "../../components/Sede/SidebarSede";
import DashboardCards from "../../components/Admin/DashboardCards";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const HomeAdmin = () => {
    const { auth } = useAuth();
    const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
    const [dashboardInfo, setDashboardInfo] = useState({})

    useEffect(() => {
        if(jwtDecode(auth.accessToken).firstLog === true){
            navigate('/change-password')
        }
        let isMounted = true;
        const controller = new AbortController();
        const getCantidadAtletas = async () => {
            try{
                const response = await axiosPrivate.get(`/api/dashboardinfo/`, {
                    signal: controller.signal
                });
                isMounted && setDashboardInfo(response.data.dashboardInfo);
            } catch(err) {
                console.error(err);
            }
        }
        getCantidadAtletas();
    }, [auth.accessToken, navigate]);



    return(
        <section className="HomeAdmin">
            { jwtDecode(auth.accessToken).role === 1200
                ?<SidebarSede />
                :<Sidebar />
            }
            <div className="main">
                <h1>Dashboard</h1>
                <div className="row">
                    <DashboardCards 
                    title="Atletas totales"
                    count={dashboardInfo.totalAtletas}
                    icon={"users"}
                    color={"flat-color-1"}
                    />
                    <DashboardCards 
                    title="Atletas de Gimnasia artistica"
                    count={dashboardInfo.gimnasiaAtletas}
                    icon={"female"}
                    color={"flat-color-2"}
                    />
                    <DashboardCards 
                    title="Atletas de Gimnasia en trampolin"
                    count={dashboardInfo.trampolinAtletas}
                    icon={"child"}
                    color={"flat-color-3"}
                    />
                    <DashboardCards 
                    title="Reportes realizados en el mes"
                    count={dashboardInfo.reportesEnMes}
                    icon={"calendar"}
                    color={"flat-color-4"}
                    />
                </div>
                
            </div>

        </section>
    )
}

export default HomeAdmin;