import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../../static/css/HomeAtletas.css'
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
import logo from "../../static/img/4.png"

const HomeAtletas = () => {
	const navigate = useNavigate();
	const logout = useLogout();
	const axiosPrivate = useAxiosPrivate();
	const { auth } = useAuth();
	const [info, setInfo] = useState({})
	const NumSocio = jwtDecode(auth.accessToken).NumSocio
	const [array, setArray] = useState({})
	
	const signOut = async () => {
		await logout();
		navigate('/login')
	}

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		if(jwtDecode(auth.accessToken).firstLog === true){
			navigate('/change-password')
		}

		const getInfo = async () => {
			try{
				const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}`, {
					signal: controller.signal
				});
				isMounted && setInfo(response.data.data);
				setArray(response.data.data.DOB.split("-"))
			} catch(err) {
			}
		}
		getInfo()
		return () => {
			isMounted = false;
			controller.abort();
		}
	}, [NumSocio, axiosPrivate, auth.accessToken, navigate])

	function age(birthdate) {
		const today = new Date();
		const age = today.getFullYear() - birthdate.getFullYear() - 
		(today.getMonth() < birthdate.getMonth() || 
		(today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()));
		return age;
	}
	let birthdate = new Date(array[2], array[1]-1, array[0])
	let ageValue = age(birthdate);

	return (
		<section className="HomeAtletas">
			{info && <div className="elements">
				<img className="logo" src={logo} alt="Logo" />
				<div className="student-profile py-4">
					<div className="">
						<div className="row">
							<div className="backTitulo">
								<div className="card cajaTitulo" >
									<div className="card-header bg-transparent text-center">
										<h3>Atleta:</h3>
										<h3>{info.Nombre + " " + info.Apellido1 + " " + info.Apellido2}</h3>
									</div>
								</div>
							</div>
      						<div className="cajaInfo">
        						<div className="card shadow-sm cajaInfo">
          							<div className="card-header bg-transparent border-0">
            							<h3 className="mb-0"><i className="far fa-clone pr-1"></i>Información General</h3>
          							</div>
          							<div className="card-body pt-0">
            							<table className="table table-bordered">
											<tbody>
              									<tr>
                									<th width="30%">Fecha de nacimiento</th>
                									<td width="2%">:</td>
                									<td>{info.DOB}</td>
									            </tr>
              									<tr>
              									  	<th width="30%">Edad </th>
              									  	<td width="2%">:</td>
              									  	<td>{ageValue}</td>
              									</tr>
              									<tr>
              									  	<th width="30%">Modalidad</th>
              									  	<td width="2%">:</td>
              									  	{info.Modalidad === "Gimnasia Artistica"?
													<td>Gimnasia Artística</td>
													:<td>{info.Modalidad}</td>
												}
              									</tr>
												{
													info.Nivel === "ExcelBronce"?
													<tr>
              									  		<th width="30%">Nivel</th>
              									  		<td width="2%">:</td>
              									  		<td>Excel Bronce</td>
              										</tr>
													:info.Nivel === "ExcelSilver"?
													<tr>
              									  		<th width="30%">Nivel</th>
              									  		<td width="2%">:</td>
              									  		<td>Excel Silver</td>
              										</tr>
													:info.Nivel === "ExcelGold"?
													<tr>
              									  		<th width="30%">Nivel</th>
              									  		<td width="2%">:</td>
              									  		<td>Excel Gold</td>
              										</tr>
													:
													<tr>
              									  		<th width="30%">Nivel</th>
              									  		<td width="2%">:</td>
              									  		<td>{info.Nivel}</td>
              										</tr>
												}
              									<tr>
              									  	<th width="30%">Centro</th>
              									  	<td width="2%">:</td>
              									  	<td>{info.Centro}</td>
              									</tr>
											</tbody>
            							</table>
          							</div>
        						</div>
		
      						</div>
    					</div>
					</div>
				</div>
				<div className="botones">
					<a href="/informe-tecnico"><button className="button"><p className="text-button">INFORME TÉCNICO</p></button></a>
                	<a href="/informe-fisico"><button className="button"><p className="text-button">INFORME FÍSICO</p></button></a>
                	<a href="/rankings"><button className="button"><p className="text-button">RANKINGS</p></button></a>
				</div>
				<button onClick={signOut} className="button"><p className="text-button">SALIR</p></button>
	  		</div> }
		</section>
	);
};
export default HomeAtletas;