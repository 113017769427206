// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rankings{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.titulo{
    text-align: center;
}
.tablas{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.rankings .tablas .resultados{
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 10vh;
  }
  
  .rankings .tablas .resultados th ,
  .rankings .tablas .resultados td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .rankings .tablas .resultados th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .rankings .tablas .resultados tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .rankings .tablas .resultados tbody tr:hover {
    background-color: #e9e9e9;
  }
  
  .rankings .tablas .resultados td:first-child,
  .rankings .tablas .resultados th:first-child {
    border-left: none;
  }
  
  .rankings .tablas .resultados td:last-child,
  .rankings .tablas .resultados th:last-child {
    border-right: none;
  }
  
  .gold {
    background-color: gold;
  }
  
  .silver {
    background-color: silver;
  }
  
  .bronze {
    background-color: #CD7F32;
  }
  
  .green {
    background-color: lawngreen;
  }

  .table-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 2vh;
  }`, "",{"version":3,"sources":["webpack://./src/static/css/Rankings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,mBAAmB;EACrB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,iBAAiB;EACnB;;EAEA;;IAEE,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB","sourcesContent":[".rankings{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.titulo{\n    text-align: center;\n}\n.tablas{\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n.rankings .tablas .resultados{\n    border-collapse: collapse;\n    width: 100%;\n    margin-bottom: 10vh;\n  }\n  \n  .rankings .tablas .resultados th ,\n  .rankings .tablas .resultados td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  .rankings .tablas .resultados th {\n    background-color: #f2f2f2;\n    text-align: left;\n  }\n  \n  .rankings .tablas .resultados tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .rankings .tablas .resultados tbody tr:hover {\n    background-color: #e9e9e9;\n  }\n  \n  .rankings .tablas .resultados td:first-child,\n  .rankings .tablas .resultados th:first-child {\n    border-left: none;\n  }\n  \n  .rankings .tablas .resultados td:last-child,\n  .rankings .tablas .resultados th:last-child {\n    border-right: none;\n  }\n  \n  .gold {\n    background-color: gold;\n  }\n  \n  .silver {\n    background-color: silver;\n  }\n  \n  .bronze {\n    background-color: #CD7F32;\n  }\n  \n  .green {\n    background-color: lawngreen;\n  }\n\n  .table-title {\n    font-size: 18px;\n    font-weight: bold;\n    margin-top: 2vh;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
