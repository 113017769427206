import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../static/css/Login.css";
import axios from "../../utils/axios";
import logo from "../../static/img/4.png";
import phone from "../../static/img/2.png";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
const LOGIN_URL = "/auth/token/obtain/";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    localStorage.removeItem("refreshToken");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const accessToken = response?.data?.access;
      const roles = [jwtDecode(response?.data?.access).role];
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("persist", true);

      setAuth({ username, password, roles, accessToken });
      setUsername("");
      setPassword("");
      if (roles[0] === 1100) {
        navigate("/admin", { replace: true });
      } else if (roles[0] === 1200) {
        navigate("/admin-sede", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        alert("Error del servidor. Por favor intente más tarde.");
      } else if (err.response?.status === 400) {
        alert("Missing Username or Password");
      } else if (err.response?.status === 401) {
        alert("Usuario o contraseña incorrecto.");
      } else {
        alert("Error del inicio de sesión.");
      }
    }
  };
  return (
    <div className="login">
      <div className="bg-img d-flex justify-content-center align-items-center">
        <div className="container container-fluid p-0">
          <img src={logo} alt="Logo" className="lg w-50 mb-2 img-fluid mt-2" />
          <div className="PhoneForm container-lg p-1 m-0">
            <form
              onSubmit={handleSubmit}
              className="loginf container-xl p-0 m-0"
            >
              <div className="input-group mb-2">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi bi-person-fill"></i>
                </span>
                <input
                  type="text"
                  className="form-control lead"
                  placeholder="Número de Socio o Usuario"
                  aria-label="Número de Socio o Usuario"
                  aria-describedby="basic-addon1"
                  ref={userRef}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="input-group mb-2">
                <span className="input-group-text" id="basic-addon2">
                  <i className="bi bi-lock-fill"></i>
                </span>
                <input
                  type="password"
                  className="form-control lead"
                  placeholder="Contraseña"
                  aria-label="Contraseña"
                  aria-describedby="basic-addon2"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="btn w-100 p-0 m-0">
                <button type="submit" className="btn btn-primary p-2 w-100">
                  Inicia Sesión
                </button>
              </div>
            </form>
            <img src={phone} alt="Logo" className="phone w-50 h-50 img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
