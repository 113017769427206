// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestTecnico{
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}
.Titulo{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.TestTecnicoContent{
    margin-top: 3vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ActualizarNivel{
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.SelectorNivel{
    margin-top: 1vh;
    margin-bottom: 1vh;
}`, "",{"version":3,"sources":["webpack://./src/static/css/TestTecnico.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".TestTecnico{\n    display: flex;\n    flex-direction: row;\n    min-height: 100vh;\n}\n.Titulo{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    margin-left: auto;\n    margin-right: auto;\n}\n.TestTecnicoContent{\n    margin-top: 3vh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.ActualizarNivel{\n    margin-top: 5vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.SelectorNivel{\n    margin-top: 1vh;\n    margin-bottom: 1vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
