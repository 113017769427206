import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/Admin/Sidebar';
// import '../../static/css/InfoDetailed.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth';
import SidebarSede from '../../components/Sede/SidebarSede';
import jwtDecode from 'jwt-decode';


const AgregarResultado = () => {
    const NivelesBajos = ["Baby Gym", "Little Gym", "Little Star", "Beginner"];
    const NivelesNumericos = ["1", "2", "3", "4"];
    const [atleta, setAtleta] = useState();
    const axiosPrivate = useAxiosPrivate();
    const { NumSocio } = useParams();
    const { auth } = useAuth();
    const [nivelURL, setNivelURL] = useState("");
    const [Competencia, setCompetencia] = useState("");
    const [PuntajeSalto, setPuntajeSalto] = useState();
    const [PuestoSalto, setPuestoSalto] = useState();
    const [PuntajeBarra, setPuntajeBarra] = useState();
    const [PuestoBarra, setPuestoBarra] = useState();
    const [PuntajeViga, setPuntajeViga] = useState();
    const [PuestoViga, setPuestoViga] = useState();
    const [PuntajePiso, setPuntajePiso] = useState();
    const [PuestoPiso, setPuestoPiso] = useState();
    const [PuntajeAA, setPuntajeAA] = useState();
    const [PuestoAA, setPuestoAA] = useState();
    
    
    useEffect(() => {
      const getUser = async () => {
        try {
          const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}`);
          setAtleta(response.data.data);
          if (NivelesBajos.includes(response.data.data.Nivel)){
              setNivelURL("nivelesbajos");
          }
          else if(NivelesNumericos.includes(response.data.data.Nivel)){
              setNivelURL("nivel" + response.data.data.Nivel);
          }
          else{
              setNivelURL("nivelexcel");
          }
        } catch (err) {
        console.error(err);
      }
    };
    getUser();
  }, [axiosPrivate, NumSocio]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(PuestoBarra)
      await axios.post(
        `/api/atleta/resultados/${nivelURL}/`,
        JSON.stringify({
          NumSocio, Competencia, PuntajeSalto, PuestoSalto, PuntajeBarra, PuestoBarra, PuntajeViga, PuestoViga, PuntajePiso, PuestoPiso, PuntajeAA, PuestoAA,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      alert('Resultados agregados exitosamente!');
    } catch (err) {
      console.log(err);
      alert('Ha ocurrido un error, intente de nuevo.');
    }
  };
  return (
    <section className='InfoDetailed'>
      { jwtDecode(auth.accessToken).role === 1200
      ?<SidebarSede />
      :<Sidebar />
      }
      {atleta && <div className='InfoEditContent'>
        <h1>Editar informacion del usuario</h1>
        <form onSubmit={handleSubmit} className="EditForm">
          <label htmlFor="Nombre">Competencia:</label>
          <input name='Competencia' required type='text' className='text-field' placeholder='Competencia' value={Competencia} onChange={(e) => setCompetencia(e.target.value)}/>
          {/* Salto */}
          <label htmlFor="PuntajeSalto">Puntaje Salto:</label>
          <input name='PuntajeSalto' type='text' className='text-field' placeholder='Puntaje Salto' value={PuntajeSalto} onChange={(e) => setPuntajeSalto(e.target.value)}/>
          <label htmlFor="PuestoSalto">Puesto Salto:</label>
          <input name='PuestoSalto' type='text' className='text-field' placeholder='Puesto Salto' value={PuestoSalto} onChange={(e) => setPuestoSalto(e.target.value)}/>
          {/* Barra */}
          <label htmlFor="PuntajeBarra">Puntaje Barra:</label>
          <input name='PuntajeBarra' type='text' className='text-field' placeholder='Puntaje Barra' value={PuntajeBarra} onChange={(e) => setPuntajeBarra(e.target.value)}/>
          <label htmlFor="PuestoBarra">Puesto Barra:</label>
          <input name='PuestoBarra' type='text' className='text-field' placeholder='Puesto Barra' value={PuestoBarra} onChange={(e) => setPuestoBarra(e.target.value)}/>
          {/* Viga */}
          <label htmlFor="PuntajeViga">Puntaje Viga:</label>
          <input name='PuntajeViga' type='text' className='text-field' placeholder='Puntaje Viga' value={PuntajeViga} onChange={(e) => setPuntajeViga(e.target.value)}/>
          <label htmlFor="PuestoViga">Puesto Viga:</label>
          <input name='PuestoViga' type='text' className='text-field' placeholder='Puesto Viga' value={PuestoViga} onChange={(e) => setPuestoViga(e.target.value)}/>
          {/* Piso */}
          <label htmlFor="PuntajeSalto">Puntaje Piso:</label>
          <input name='PuntajePiso' type='text' className='text-field' placeholder='Puntaje Piso' value={PuntajePiso} onChange={(e) => setPuntajePiso(e.target.value)}/>
          <label htmlFor="PuestoPiso">Puesto Piso:</label>
          <input name='PuestoPiso' type='text' className='text-field' placeholder='Puesto Piso' value={PuestoPiso} onChange={(e) => setPuestoPiso(e.target.value)}/>
          {/* AA */}
          <label htmlFor="PuntajeSalto">Puntaje AA:</label>
          <input name='PuntajeAA' type='text' className='text-field' placeholder='Puntaje AA' value={PuntajeAA} onChange={(e) => setPuntajeAA(e.target.value)}/>
          <label htmlFor="PuestoPiso">Puesto AA:</label>
          <input name='PuestoAA' type='text' className='text-field' placeholder='Puesto AA' value={PuestoAA} onChange={(e) => setPuestoAA(e.target.value)}/>
          <input type="submit" className="button" value="Actualizar" />
        </form>
        </div>
      }
    </section>
  );
};

export default AgregarResultado;