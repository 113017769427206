import React, { useState } from "react";
import Sidebar from "../../components/Admin/Sidebar";
import "../../static/css/AgregarAtleta.css";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import SidebarSede from "../../components/Sede/SidebarSede";
import jwtDecode from "jwt-decode";
const URL = "/api/atleta/info/";

const AgregarAtleta = () => {
  const [NumSocio, setNumSocio] = useState("");
  const [Nombre, setNombre] = useState("");
  const [Apellido1, setApellido1] = useState("");
  const [Apellido2, setApellido2] = useState("");
  const [Email, setEmail] = useState("");
  const [DOB, setDOB] = useState("");
  const [Nivel, setNivel] = useState("");
  const [Modalidad, setModalidad] = useState("");
  const [Centro, setCentro] = useState("");
  const { auth } = useAuth();

  // Arreglos de Selectores
  const NivelGimnasiaArtisticaOptions = [
    { value: "", text: "Nivel" },
    { value: "Baby Gym", text: "Baby Gym" },
    { value: "Little Gym", text: "Little Gym" },
    { value: "Little Star", text: "Little Star" },
    { value: "Beginner", text: "Beginner" },
    { value: "1", text: "Nivel 1" },
    { value: "2", text: "Nivel 2" },
    { value: "3", text: "Nivel 3" },
    { value: "4", text: "Nivel 4" },
    { value: "5", text: "Nivel 5" },
    { value: "6", text: "Nivel 6" },
    { value: "7", text: "Nivel 7" },
    { value: "ExcelBronce", text: "Excel Bronce" },
    { value: "ExcelSilver", text: "Excel Silver" },
    { value: "ExcelGold", text: "Excel Gold" },
  ];

  const NivelTrampolinOptions = [
    { value: "", text: "Nivel" },
    { value: "Primer Año", text: "Primer Año" },
    { value: "Segundo Año", text: "Segundo Año" },
    { value: "Tercer Año", text: "Tercer Año" },
    { value: "Cuarto Año", text: "Cuarto Año" },
    { value: "Quinto Año", text: "Quinto Año" },
    { value: "Sexto Año", text: "Sexto Año" },
    { value: "Séptimo Año", text: "Séptimo Año" },
    { value: "Octavo Año", text: "Octavo Año" },
    { value: "Noveno Año", text: "Noveno Año" },
    { value: "Décimo Año", text: "Décimo Año" },
    { value: "Undécimo Año", text: "Undécimo Año" },
    { value: "Duodécimo Año", text: "Duodécimo Año" },
    { value: "Décimotercer Año", text: "Décimotercer Año" },
  ];

  const [NivelSelected, setNivelSelected] = useState("");

  const ModalidadOptions = [
    { value: "", text: "Modalidad" },
    { value: "Gimnasia Artistica", text: "Gimnasia Artistica" },
    { value: "Trampolin", text: "Trampolin" },
  ];

  const [ModalidadSelected, setModalidadSelected] = useState(
    ModalidadOptions[0].value
  );

  const CentroOptions = [
    { value: "", text: "Centro" },
    { value: "Costa del Este", text: "Costa del Este" },
    { value: "Condado del Rey", text: "Condado del Rey" },
  ];

  const [CentroSelected, setCentroSelected] = useState(CentroOptions[0].value);

  // Funciones de selectores

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        URL,
        JSON.stringify({
          NumSocio,
          Nombre,
          Apellido1,
          Apellido2,
          Email,
          DOB,
          Nivel,
          Modalidad,
          Centro,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      setNumSocio("");
      setNombre("");
      setApellido1("");
      setApellido2("");
      setEmail("");
      setDOB("");
      setNivel("");
      setModalidad(ModalidadOptions[0].value);
      setCentro(CentroOptions[0].value);
      alert("Atleta agregado correctamente!");
    } catch (err) {}
  };

  const handleChangeNivel = (event) => {
    setNivelSelected(event.target.value);
    setNivel(event.target.value);
  };

  const handleChangeModalidad = (event) => {
    setModalidadSelected(event.target.value);
    setModalidad(event.target.value);
  };

  const handleChangeCentro = (event) => {
    setCentroSelected(event.target.value);
    setCentro(event.target.value);
  };

  return (
    <section className="AgregarAtleta">
      {jwtDecode(auth.accessToken).role === 1200 ? (
        <SidebarSede />
      ) : (
        <Sidebar />
      )}
      <div className="AgregarAtletaContent">
        <h1>Agregar Atleta</h1>
        <form onSubmit={handleSubmit} className="AgregarForm">
          <input
            name="NumSocio"
            type="text"
            class="text-field"
            placeholder="Número de socio"
            value={NumSocio}
            onChange={(e) => setNumSocio(e.target.value)}
            required
          />
          <input
            name="Nombre"
            type="text"
            class="text-field"
            placeholder="Nombre"
            value={Nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <input
            name="Apellido1"
            type="text"
            class="text-field"
            placeholder="Primer Apellido"
            value={Apellido1}
            onChange={(e) => setApellido1(e.target.value)}
            required
          />
          <input
            name="Apellido2"
            type="text"
            class="text-field"
            placeholder="Segundo Apellido"
            value={Apellido2}
            onChange={(e) => setApellido2(e.target.value)}
          />
          <input
            name="Email"
            type="email"
            class="text-field"
            placeholder="Correo Electronico"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            name="DOB"
            type="date"
            class="text-field"
            placeholder="DD/MM/YYYY"
            value={DOB}
            onChange={(e) => setDOB(e.target.value)}
            required
          />
          {/* Selector de Modalidad */}
          <select
            className="text-field"
            value={ModalidadSelected}
            onChange={handleChangeModalidad}
            required
          >
            {ModalidadOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          {/* Selector de Nivel */}
          {Modalidad === "Gimnasia Artistica" ? (
            <select
              className="text-field"
              value={NivelSelected}
              onChange={handleChangeNivel}
              required
            >
              {NivelGimnasiaArtisticaOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          ) : Modalidad === "Trampolin" ? (
            <select
              className="text-field"
              value={NivelSelected}
              onChange={handleChangeNivel}
              required
            >
              {NivelTrampolinOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          ) : (
            <></>
          )}

          {/* Selector de Centro */}
          <select
            className="text-field"
            value={CentroSelected}
            onChange={handleChangeCentro}
            required
          >
            {CentroOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          <input type="submit" class="button" value="Enviar" />
        </form>
      </div>
    </section>
  );
};

export default AgregarAtleta;
