import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Split, Flexion, SaltoHorizontal, Velocidad, ElevacionPiernas, ElevacionTronco, Barbilla, Fondos, Sostener, SaltoVertical } from "../../hooks/calculatarPuntajes";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export function GraficoFlexibilidad(props) {
	const axiosPrivate = useAxiosPrivate();
  const [calificaciones, setCalificaciones] = useState();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  
  const enlacesFlexibilidad = ["https://www.youtube.com/watch?v=EaICVz0gAQ8&feature=youtu.be", "https://www.youtube.com/watch?v=zmntRJT-W0g&feature=youtu.be", "https://www.youtube.com/watch?v=CfPEnj5iMCY&feature=youtu.be", "https://www.youtube.com/watch?v=agNuxz4PtIc&feature=youtu.be", "", "http://www.youtube.com/watch?v=mGEYmzasAD0&feature=youtu.be"];

    useEffect(() => {
        const getCalificaciones = async () => {
          try {
                const response = await axiosPrivate.get(`/api/atleta/info/testfisico/${props.NumSocio}/`);
                setCalificaciones(response.data.data);
                setSeries([{
                    name: "Ejecutado",
                    type: "column",
                    data: [response.data.data.Flexibilidad1, response.data.data.Flexibilidad2, response.data.data.Flexibilidad3, response.data.data.Flexibilidad4, response.data.data.Flexibilidad5, response.data.data.Flexibilidad6]//your data goes here
                  },
                  {
                    name: "Puntuación",
                    type: "line",
                    data: [Split(response.data.data.Flexibilidad1), Split(response.data.data.Flexibilidad2), response.data.data.Flexibilidad3, Flexion(response.data.data.Flexibilidad4), Flexion(response.data.data.Flexibilidad5), Flexion(response.data.data.Flexibilidad6)]
                  },]) 
          } catch (err) {
          }
        };
        getCalificaciones();
   

    setOptions({
    chart: {
      height: 500,
      type: "line",
      events: {
        dataPointSelection: (event, chartContext, config) => { 
          window.location.replace(enlacesFlexibilidad[config.dataPointIndex])
        }
      }
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    labels: ['Split Antero-Posterior', 'Split Lateral', 'Arco', 'Flexión Tronco al Frente', 'Flexión de Hombros', ['Flexión Tronco al Frente', 'con Piernas Separadas']],
    yaxis: [
      {
        title: {
          text: "Ejecución del Ejercicio",
        },
      },
    ],
  });
}, [axiosPrivate, props.NumSocio]);
    

  return (
    <>
      {calificaciones && <ReactApexChart
        options={options}
        series={series}
        type='line'
        height={500}
        width={1000}
      />}
    </>
  );
}


export function GraficoFuerza(props) {
	const axiosPrivate = useAxiosPrivate();
    const [calificaciones, setCalificaciones] = useState();
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({});
    const enlacesFuerza = ["https://www.youtube.com/watch?v=2D8HgTR79Hk&feature=youtu.be", "https://www.youtube.com/watch?v=syi-6wqXvCY&feature=youtu.be", "https://www.youtube.com/watch?v=FyMpVaT3bpU&feature=youtu.be", "https://www.youtube.com/watch?v=Bmkxj7WdMCU&feature=youtu.be", "https://www.youtube.com/watch?v=lUh01R-uueo&feature=youtu.be", "https://www.youtube.com/watch?v=LFBFxp8orqc&feature=youtu.be", "https://www.youtube.com/watch?v=LFBFxp8orqc&feature=youtu.be", "", "", "https://www.youtube.com/watch?v=Qrkstcv93rQ&feature=youtu.be", "https://www.youtube.com/watch?v=Qrkstcv93rQ&feature=youtu.be"];

    useEffect(() => {
        const getCalificaciones = async () => {
          try {
                const response = await axiosPrivate.get(`/api/atleta/info/testfisico/${props.NumSocio}/`);
                setCalificaciones(response.data.data);
                setSeries([{
                    name: "Ejecutado",
                    type: "column",
                    data: [response.data.data.Fuerza1, response.data.data.Fuerza2, response.data.data.Fuerza3, response.data.data.Fuerza4, response.data.data.Fuerza5, response.data.data.Fuerza6, response.data.data.Fuerza7, response.data.data.Fuerza8, response.data.data.Fuerza9, response.data.data.Fuerza10, response.data.data.Fuerza11]//your data goes here
                  },
                  {
                    name: "Puntuación",
                    type: "line",
                    data: [SaltoHorizontal(response.data.data.Fuerza1), Velocidad(response.data.data.Fuerza2), ElevacionPiernas(response.data.data.Fuerza3), ElevacionTronco(response.data.data.Fuerza4), Barbilla(response.data.data.Fuerza5), Fondos(response.data.data.Fuerza6), Sostener(response.data.data.Fuerza7), SaltoVertical(response.data.data.Fuerza8), response.data.data.Fuerza9, response.data.data.Fuerza10, response.data.data.Fuerza11]//your data goes here
                  },]) 
          } catch (err) {
          }
        };
        getCalificaciones();
   

    setOptions({
    chart: {
      height: 500,
      type: "line",
      events: {
        dataPointSelection: (event, chartContext, config) => { 
          window.location.replace(enlacesFuerza[config.dataPointIndex])
        }
      }
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    labels: [['Salto', 'Horizontal'], 'Velocidad', ['Elevación de Piernas desde la', 'Suspensión (Escuadras) a 90º'], 'Elevación de Tronco al Frente', ['Barbilla a la Barra',  'Mantener Biceps'], 'Fondos Barra', ['Sostener Para de Manos', 'Longitudinal en Viga Baja'], 'Salto Vertical', 'Subidas a Fuerza en Barra', 'Olímpicos en Barra', ['Impulso Atras a Parada de', ' Manos(Vertical) en Barra']],
    yaxis: [
      {
        title: {
          text: "Ejecución del Ejercicio",
        },
      },
    ],
  });
}, [axiosPrivate, props.NumSocio]);
    

  return (
    <>
      {calificaciones && <ReactApexChart
        options={options}
        series={series}
        type='line'
        height={500}
        width={1000}
      />}
    </>
  );
}
