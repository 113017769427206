import React, { useState, useEffect } from 'react';
import "../../static/css/Nivel4.css"
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const PrimerAno = (props) => {
	  const axiosPrivate = useAxiosPrivate();
    const { NumSocio } = useParams();
    const [Elemento1, setElemento1] = useState("");
    const [Elemento2, setElemento2] = useState("");
    const [Elemento3, setElemento3] = useState("");
    const [Elemento4, setElemento4] = useState("");
    const [Elemento5, setElemento5] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axiosPrivate.post(
            `/api/atleta/info/trampolin/`,
            JSON.stringify({
              NumSocio: NumSocio,
              Elemento1 : Elemento1,
              Elemento2 : Elemento2,
              Elemento3 : Elemento3,
              Elemento4 : Elemento4,
              Elemento5 : Elemento5,
            }),
          );
          alert('Informacion actualizada correctamente!');
        } catch (err) {
          console.log(err);
        }
      };
    return(
        <section className="Nivel4">
            <h3 className="Categoria">Deporte: Trampolin</h3>
            <h3 className="Categoria">Categoria: Primer Año</h3>
            <form onSubmit={handleSubmit} className="Calificaciones">
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1:  Espalda Pie</h4>
                        <input name='Elemento1Salto' type='text' className='CalificacionesInput' value={Elemento1} onChange={(e) => setElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: Back O</h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={Elemento2} onChange={(e) => setElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: Front O</h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={Elemento3} onChange={(e) => setElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: Front {"<"}</h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={Elemento4} onChange={(e) => setElemento4(e.target.value )}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: Back /</h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={Elemento5} onChange={(e) => setElemento5(e.target.value )}/>
                    </div>
                </div>                
                <input type="submit" className="button" value="Actualizar" />
            </form>
        </section>
    )
}

export default PrimerAno;