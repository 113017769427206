import React, { useState, useEffect } from 'react';
import "../../static/css/Nivel4.css"
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth'

const NivelExcelBronce = (props) => {
    const { data } = props;
	const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [SaltoElemento1, setSaltoElemento1] = useState("");
    const [SaltoElemento2, setSaltoElemento2] = useState("");
    const [SaltoRutina, setSaltoRutina] = useState("");
    const [BarraElemento1, setBarraElemento1] = useState("");
    const [BarraElemento2, setBarraElemento2] = useState("");
    const [BarraElemento3, setBarraElemento3] = useState("");
    const [BarraElemento4, setBarraElemento4] = useState("");
    const [BarraRutina, setBarraRutina] = useState("");
    const [VigaElemento1, setVigaElemento1] = useState("");
    const [VigaElemento2, setVigaElemento2] = useState("");
    const [VigaElemento3, setVigaElemento3] = useState("");
    const [VigaElemento4, setVigaElemento4] = useState("");
    const [VigaRutina, setVigaRutina] = useState("");
    const [PisoElemento1, setPisoElemento1] = useState("");
    const [PisoElemento2, setPisoElemento2] = useState("");
    const [PisoElemento3, setPisoElemento3] = useState("");
    const [PisoElemento4, setPisoElemento4] = useState("");
    const [PisoRutina, setPisoRutina] = useState("");
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(
            `/api/atleta/info/nivelexcelbronce/`,
            JSON.stringify({
                NumSocio: data.NumSocio,
                SaltoElemento1 : SaltoElemento1,
                SaltoElemento2 : SaltoElemento2,
                SaltoRutina : SaltoRutina,
                BarraElemento1 : BarraElemento1,
                BarraElemento2 : BarraElemento2,
                BarraElemento3 : BarraElemento3,
                BarraElemento4 : BarraElemento4,
                BarraRutina : BarraRutina,
                VigaElemento1 : VigaElemento1,
                VigaElemento2 : VigaElemento2,
                VigaElemento3 : VigaElemento3,
                VigaElemento4 : VigaElemento4,
                VigaRutina : VigaRutina,
                PisoElemento1 : PisoElemento1,
                PisoElemento2 : PisoElemento2,
                PisoElemento3 : PisoElemento3,
                PisoElemento4 : PisoElemento4,
                PisoRutina : PisoRutina,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${auth.accessToken}`,
              },
            }
          );
          alert('Informacion actualizada correctamente!');
          navigate(-1);
        } catch (err) {
          console.log(err);
        }
      };
    return(
        <section className="Nivel4">
            <h3 className="Categoria">Categoria: Excel Bronce</h3>
            <form onSubmit={handleSubmit} className="Calificaciones">
                <h4 className="TituloGrupo">Salto</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1:  CARRERA DE APROXIMACION AL TRAMPOLIN</h4>
                        <input name='Elemento1Salto' type='text' className='CalificacionesInput' value={SaltoElemento1} onChange={(e) => setSaltoElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: SALTO NIVEL BRONCE </h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={SaltoElemento2} onChange={(e) => setSaltoElemento2(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4>Rutina: </h4>
                        <input name='RutinaSalto' type='text' className='CalificacionesInput' value={SaltoRutina} onChange={(e) => setSaltoRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Barra</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: SUBIDA A FUERZA</h4>
                        <input name='Elemento1Barra' type='text' className='CalificacionesInput' value={BarraElemento1} onChange={(e) => setBarraElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: DESPEGUE A SEPARAR</h4>
                        <input name='Elemento2Barra' type='text' className='CalificacionesInput' value={BarraElemento2} onChange={(e) => setBarraElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: VUELTA ATRÁS</h4>
                        <input name='Elemento3Barra' type='text' className='CalificacionesInput' value={BarraElemento3} onChange={(e) => setBarraElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: SALIDA CANASTA/SALIDA MONTADA DE PIERNAS/SALIDA DESPEGUE A CAIDA</h4>
                        <input name='Elemento4Barra' type='text' className='CalificacionesInput' value={BarraElemento4} onChange={(e) => setBarraElemento4(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaBarra' type='text' className='CalificacionesInput' value={BarraRutina} onChange={(e) => setBarraRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Viga</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: ENTRADA A/SALIDA A</h4>
                        <input name='Elemento1Viga' type='text' className='CalificacionesInput' value={VigaElemento1} onChange={(e) => setVigaElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: 1/2 GIRO CUCLILLAS / 1/2 GIRO RELEVE</h4>
                        <input name='Elemento2Viga' type='text' className='CalificacionesInput' value={VigaElemento2} onChange={(e) => setVigaElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: ZANCADA NO ANGULO/SALTO SPLIT NO ANGULO</h4>
                        <input name='Elemento3Viga' type='text' className='CalificacionesInput' value={VigaElemento3} onChange={(e) => setVigaElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: Aguja/Palanca / 1/2 parada de manos, 45°</h4>
                        <input name='Elemento4Viga' type='text' className='CalificacionesInput' value={VigaElemento4} onChange={(e) => setVigaElemento4(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaViga' type='text' className='CalificacionesInput' value={VigaRutina} onChange={(e) => setVigaRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Piso</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: Rondada rebote voltereta atrás/Parada de manos voltereta</h4>
                        <input name='Elemento1Piso' type='text' className='CalificacionesInput' value={PisoElemento1} onChange={(e) => setPisoElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: 1/2 giro sobre un píe</h4>
                        <input name='Elemento2Piso' type='text' className='CalificacionesInput' value={PisoElemento2} onChange={(e) => setPisoElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: Zancada + salto vela/Zancada + rodillas al pecho/Zancada + salto split</h4>
                        <input name='Elemento3Piso' type='text' className='CalificacionesInput' value={PisoElemento3} onChange={(e) => setPisoElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: 1/2 luna/Rondada</h4>
                        <input name='Elemento4Piso' type='text' className='CalificacionesInput' value={PisoElemento4} onChange={(e) => setPisoElemento4(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaPiso' type='text' className='CalificacionesInput' value={PisoRutina} onChange={(e) => setPisoRutina(e.target.value)}/>
                    </div>
                </div>
                <input type="submit" className="button" value="Actualizar" />
            </form>
        </section>
    )
}

export default NivelExcelBronce;