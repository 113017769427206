import React, { useState } from 'react';
import '../../static/css/AgregarAdministrador.css'
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import SidebarSede from '../../components/Sede/SidebarSede';
const URL = '/api/centro/info/'

const AgregarAdministradorCentro = () => {
  const [username, setUsername] = useState("");
  const [Nombre, setNombre] = useState("");
  const [Apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const { auth } = useAuth();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(URL,
          JSON.stringify({ username, Nombre, Apellido, email }),
          {
              headers: { 
              'Content-Type': 'application/json', 
              "Authorization": `JWT ${auth.accessToken}`,
            },
          }
      );
      setUsername('');
      setNombre('');
      setApellido('');
      setEmail('');
      alert("Usuario creado correctamente!")
   }catch (err) {
      console.log(err);
  }
  }

  return (
    <section className='AgregarAdministrador'>
      <SidebarSede />
      <div className='AgregarAdministradorContent'>
        <h1>Agregar usuario administrador de sede</h1>
        <form onSubmit={handleSubmit} className="AgregarForm">
        <input name="username" type="text" class="text-field" placeholder="Nombre de usuario" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <input name="Nombre" type="text" class="text-field" placeholder="Nombre" value={Nombre} onChange={(e) => setNombre(e.target.value)} required />
        <input name="Apellido1" type="text" class="text-field" placeholder="Apellido" value={Apellido} onChange={(e) => setApellido(e.target.value)} required />
        <input name="Email" type="email" class="text-field" placeholder="Correo Electronico" value={email} onChange={(e) => setEmail(e.target.value)} required />
        <input type="submit" class="button" value="Agregar" />
        </form>
      </div>
    </section>
  );
};

export default AgregarAdministradorCentro;