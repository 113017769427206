import React, { useState } from 'react';
import SidebarSede from "../../components/Sede/SidebarSede";
import '../../static/css/ImportarAtletas.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth';

const ImportarAtletas = () => {
    const [file, setFile] = useState(null);
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [progress, setProgress] = useState(0);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }
    const handleFileUpload = () => {
        const formData = new FormData();
        formData.append('file', file);
        axiosPrivate.post('/api/centro/uploaddb/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
              },
            onUploadProgress: progressEvent => {
              setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }
          })
          .then(response => {
            alert("Base de datos subida correctamente.")
          })
          .catch(error => {
            console.log(error);
          });
      }

    return(
        <section className="ImportarAtletas">
            <SidebarSede />
            <div className="FileUpload">
                <h1>Importación de base de datos</h1>
                <h3>El formato para subir el archivo debe ser CSV.</h3>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleFileUpload} className="button">Subir</button>
                {progress > 0 && <progress value={progress} max="100" />}
            </div>
        </section>
    );
}

export default ImportarAtletas;