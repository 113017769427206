import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Admin/Sidebar";
import "../../static/css/TestTecnico.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NivelesBajos from "../../components/Admin/NivelesBajos";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import Nivel2 from "../../components/Admin/Nivel2";
import jwtDecode from "jwt-decode";
import SidebarSede from "../../components/Sede/SidebarSede";
import Nivel3 from "../../components/Admin/Nivel3";
import Nivel4 from "../../components/Admin/Nivel4";
import NivelExcelBronce from "../../components/Admin/NivelExcelBronce";
import NivelExcelSilver from "../../components/Admin/NivelExcelSilver";
import NivelExcelGold from "../../components/Admin/NivelExcelGold";
import PrimerAno from "../../components/Admin/PrimerAno";
import SegundoAno from "../../components/Admin/SegundoAno";
import TercerAno from "../../components/Admin/TercerAno";
import CuartoAno from "../../components/Admin/CuartoAno";
import QuintoAno from "../../components/Admin/QuintoAno";
import SextoAno from "../../components/Admin/SextoAno";
import SeptimoAno from "../../components/Admin/SeptimoAno";
import OctavoAno from "../../components/Admin/OctavoAno";
import NovenoAno from "../../components/Admin/NovenoAno";
import DecimoAno from "../../components/Admin/DecimoAno";
import UndecimoAno from "../../components/Admin/UndecimoNivel";
import DuodecimoAno from "../../components/Admin/DuodecimoAno";
import DecimotercerAno from "../../components/Admin/DecimotercerAno";

const TestTecnico = () => {
  const NivelesBajosArreglo = [
    "Baby Gym",
    "Little Gym",
    "Little Star",
    "Beginner",
    "1",
  ];
  const [atleta, setAtleta] = useState();
  const [nivel, setNivel] = useState();
  const navigate = useNavigate();
  const [Modalidad, setModalidad] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { NumSocio } = useParams();
  const [selected, setSelected] = useState();
  const { auth } = useAuth();
  const optionsArtistica = [
    { value: "", text: "Nivel" },
    { value: "Baby Gym", text: "Baby Gym" },
    { value: "Little Gym", text: "Little Gym" },
    { value: "Little Star", text: "Little Star" },
    { value: "Beginner", text: "Beginner" },
    { value: "1", text: "Nivel 1" },
    { value: "2", text: "Nivel 2" },
    { value: "3", text: "Nivel 3" },
    { value: "4", text: "Nivel 4" },
    { value: "5", text: "Nivel 5" },
    { value: "6", text: "Nivel 6" },
    { value: "7", text: "Nivel 7" },
    { value: "ExcelBronce", text: "Excel Bronce" },
    { value: "ExcelSilver", text: "Excel Silver" },
    { value: "ExcelGold", text: "Excel Gold" },
  ];
  const optionsTrampolin = [
    { value: "", text: "Nivel" },
    { value: "Primer Año", text: "Primer Año" },
    { value: "Segundo Año", text: "Segundo Año" },
    { value: "Tercer Año", text: "Tercer Año" },
    { value: "Cuarto Año", text: "Cuarto Año" },
    { value: "Quinto Año", text: "Quinto Año" },
    { value: "Sexto Año", text: "Sexto Año" },
    { value: "Séptimo Año", text: "Séptimo Año" },
    { value: "Octavo Año", text: "Octavo Año" },
    { value: "Noveno Año", text: "Noveno Año" },
    { value: "Décimo Año", text: "Décimo Año" },
    { value: "Undécimo Año", text: "Undécimo Año" },
    { value: "Duodécimo Año", text: "Duodécimo Año" },
    { value: "Décimotercer Año", text: "Décimotercer Año" },
  ];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(
          `/api/atleta/info/${NumSocio}`,
          {
            signal: controller.signal,
          }
        );
        isMounted && setAtleta(response.data.data);
        setSelected(response.data.data.Nivel);
        setNivel(response.data.data.Nivel);
        setModalidad(response.data.data.Modalidad);
      } catch (err) {
        console.error(err);
      }
    };
    getUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, NumSocio]);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleSubmitNivel = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `/api/atleta/info/${NumSocio}/`,
        JSON.stringify({
          Nivel: selected,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      alert("Nivel actualizado correctamente!");
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  let renderedComponent;
  if (Modalidad === "Gimnasia Artistica") {
    switch (nivel) {
      case "2":
        renderedComponent = <Nivel2 data={atleta} />;
        break;
      case "3":
        renderedComponent = <Nivel3 data={atleta} />;
        break;
      case "4":
        renderedComponent = <Nivel4 data={atleta} />;
        break;
      case "ExcelBronce":
        renderedComponent = <NivelExcelBronce data={atleta} />;
        break;
      case "ExcelSilver":
        renderedComponent = <NivelExcelSilver data={atleta} />;
        break;
      case "ExcelGold":
        renderedComponent = <NivelExcelGold data={atleta} />;
        break;
      default:
        renderedComponent = <NivelesBajos data={atleta} />;
    }
  } else {
    switch (nivel) {
      case "Primer Año":
        renderedComponent = <PrimerAno data={atleta} />;
        break;
      case "Segundo Año":
        renderedComponent = <SegundoAno data={atleta} />;
        break;
      case "Tercer Año":
        renderedComponent = <TercerAno data={atleta} />;
        break;
      case "Cuarto Año":
        renderedComponent = <CuartoAno data={atleta} />;
        break;
      case "Quinto Año":
        renderedComponent = <QuintoAno data={atleta} />;
        break;
      case "Sexto Año":
        renderedComponent = <SextoAno data={atleta} />;
        break;
      case "Séptimo Año":
        renderedComponent = <SeptimoAno data={atleta} />;
        break;
      case "Octavo Año":
        renderedComponent = <OctavoAno data={atleta} />;
        break;
      case "Noveno Año":
        renderedComponent = <NovenoAno data={atleta} />;
        break;
      case "Décimo Año":
        renderedComponent = <DecimoAno data={atleta} />;
        break;
      case "Undécimo Año":
        renderedComponent = <UndecimoAno data={atleta} />;
        break;
      case "Duodécimo Año":
        renderedComponent = <DuodecimoAno data={atleta} />;
        break;
      case "Décimotercer Año":
        renderedComponent = <DecimotercerAno data={atleta} />;
        break;
    }
  }

  return (
    <section className="TestTecnico">
      {jwtDecode(auth.accessToken).role === 1200 ? (
        <SidebarSede />
      ) : (
        <Sidebar />
      )}
      {atleta && (
        <div className="TestTecnicoContent">
          <div className="Titulo">
            <h1 className="TestTecnicoTitulo">Test Tecnico</h1>
            <h3 className="NombreGimnasta">
              Gimnasta: {atleta.Nombre + " " + atleta.Apellido1}
            </h3>
          </div>
          <div>{renderedComponent}</div>
          <form onSubmit={handleSubmitNivel} className="ActualizarNivel">
            <h4>Actualizar Nivel</h4>
            {Modalidad === "Gimnasia Artistica" ? (
              <select
                value={selected}
                onChange={handleChange}
                className="SelectorNivel"
              >
                {optionsArtistica.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            ) : (
              <select
                value={selected}
                onChange={handleChange}
                className="SelectorNivel"
              >
                {optionsTrampolin.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            )}
            <input type="submit" className="button" value="Actualizar Nivel" />
          </form>
        </div>
      )}
    </section>
  );
};
export default TestTecnico;
