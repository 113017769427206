import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from "../../hooks/useAuth";
import { PieChart } from 'react-minimal-pie-chart';
import SquareIcon from '@mui/icons-material/Square';
import "../../static/css/InformeTecnicoNivelesBajos.css"
import logo from "../../static/img/4.png"


const NivelExcelBronceTecnico = () => {
    const { auth } = useAuth();
	const navigate = useNavigate();
    const param = useParams();
	const axiosPrivate = useAxiosPrivate();
    const decodedAccessToken = jwtDecode(auth.accessToken);
    const NumSocio = decodedAccessToken.NumSocio || param.NumSocio;
    const [atleta, setAtleta] = useState();
    const [reportes, setReportes] = useState();
    const [calificaciones, setCalificaciones] = useState();
    const [saltoFaltante, setSaltoFaltante] = useState();
    const [barraFaltante, setBarraFaltante] = useState();
    const [vigaFaltante, setVigaFaltante] = useState();
    const [pisoFaltante, setPisoFaltante] = useState();
    const [Fecha, setFecha] = useState("");
    const colores = ['#C0504D','#9BBB59','#8064A2','#4BACC6','#F79646','#516B8A','#772C2A','#5F7530','#4D3B62','#276A7C']


    useEffect(() => {
        const getUser = async () => {
            try {
              const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}/`);
              setAtleta(response.data.data);
            } catch (err) {
            }
          };
        getUser();

        const getCalificaciones = async () => {
          try {
                const response = await axiosPrivate.get(`/api/atleta/info/nivelexcelbronce/${NumSocio}/`);
                setReportes(response.data.data);
                
            }
           catch (err) {
          }
        };
        getCalificaciones();
    }, [NumSocio, axiosPrivate]);
    const Regresar = async () => {
		navigate(-1)
	}

    function ColorElemento(elemento, color){
        if(elemento === 4){
            return "#00FF00"
        }
        else{
            return color
        }
    }

    function ColorRutina(rutina, color){
        if(rutina === 6){
            return "#00FF00"
        }
        else{
            return color
        }
    }

    const handleFechaChange = (event) => {
        const selectedId = parseInt(event.target.value);
        const selectedFecha = reportes.find(reportes => reportes.id === selectedId);
        setCalificaciones(selectedFecha);
        setFecha(selectedId);
        setSaltoFaltante(14 - parseFloat(selectedFecha.SaltoElemento1) - parseFloat(selectedFecha.SaltoElemento2) - parseFloat(selectedFecha.SaltoRutina))
        setBarraFaltante(22 - parseFloat(selectedFecha.BarraElemento1) - parseFloat(selectedFecha.BarraElemento2) - parseFloat(selectedFecha.BarraElemento3) - parseFloat(selectedFecha.BarraElemento4) - parseFloat(selectedFecha.BarraRutina))
        setVigaFaltante(22 - parseFloat(selectedFecha.VigaElemento1) - parseFloat(selectedFecha.VigaElemento2) - parseFloat(selectedFecha.VigaElemento3) - parseFloat(selectedFecha.VigaElemento4) - parseFloat(selectedFecha.VigaRutina))
        setPisoFaltante(22 - parseFloat(selectedFecha.PisoElemento1) - parseFloat(selectedFecha.PisoElemento2) - parseFloat(selectedFecha.PisoElemento3) - parseFloat(selectedFecha.PisoElemento4) - parseFloat(selectedFecha.PisoRutina))
    };
    return(
        <section className="InformeTecnicoNivelesBajos">
            <div className="tituloylogo">
                <img className="logo" src={logo} alt="logo"/>
                {atleta && reportes && <div className="TitulosInformeTecnicoNivelesBajos">
                    <h1>Informe Técnico General</h1>
                    <h2>Atleta: {atleta.Nombre + " " + atleta.Apellido1}</h2>
                    <h2>Nivel: Excel Bronce</h2>
                    <select value={Fecha} onChange={handleFechaChange} required>
                    <option value="">Selecciona una fecha</option>
                        {reportes.map((reportes, index) => (
                            <option key={`${reportes.id}-${index}`} value={reportes.id}>
                        {reportes.Fecha}
                    </option>
                    ))}
                </select>
                </div>}
            </div>
            {calificaciones && <div className="GraficosInformeTecnico">
                <div className="FilaInformeTecnico">
                    <div className="GraficoInformeTecnico">
                        <h2>Salto</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: saltoFaltante, color: '#FF0000' , label: "Faltante"},
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.SaltoElemento1), color: ColorElemento(calificaciones.SaltoElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.SaltoElemento2), color: ColorElemento(calificaciones.SaltoElemento2, colores[1]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.SaltoRutina), color: ColorRutina(calificaciones.SaltoRutina, colores[2]) },
                                ]}
                                background="black"
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((saltoFaltante/14)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.SaltoElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.SaltoElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.SaltoElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.SaltoElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.SaltoRutina, colores[2])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.SaltoRutina)/6)*100).toFixed()}%</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="GraficoInformeTecnico">
                        <h2>Barra</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: barraFaltante, color: '#FF0000' },
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.BarraElemento1), color: ColorElemento(calificaciones.BarraElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.BarraElemento2), color: ColorElemento(calificaciones.BarraElemento2, colores[1]) },
                                    { title: 'Elemento 3', value: parseFloat(calificaciones.BarraElemento3), color: ColorElemento(calificaciones.BarraElemento3, colores[2]) },
                                    { title: 'Elemento 4', value: parseFloat(calificaciones.BarraElemento4), color: ColorElemento(calificaciones.BarraElemento4, colores[3]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.BarraRutina), color: ColorRutina(calificaciones.BarraRutina, colores[4]) },
                                ]}
                                background="black"
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((barraFaltante/24)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.BarraElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.BarraElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento3, colores[2])}} />
                                    <h5>Elemento 3: {((parseFloat(calificaciones.BarraElemento3)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento4, colores[3])}} />
                                    <h5>Elemento 4: {((parseFloat(calificaciones.BarraElemento4)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.BarraRutina, colores[4])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.BarraRutina)/6)*100).toFixed()}%</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="FilaInformeTecnico">
                    <div className="GraficoInformeTecnico">
                        <h2>Viga</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: vigaFaltante, color: '#FF0000' },
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.VigaElemento1), color: ColorElemento(calificaciones.VigaElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.VigaElemento2), color: ColorElemento(calificaciones.VigaElemento2, colores[1]) },
                                    { title: 'Elemento 3', value: parseFloat(calificaciones.VigaElemento3), color: ColorElemento(calificaciones.VigaElemento3, colores[2]) },
                                    { title: 'Elemento 4', value: parseFloat(calificaciones.VigaElemento4), color: ColorElemento(calificaciones.VigaElemento4, colores[3]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.VigaRutina), color: ColorRutina(calificaciones.VigaRutina, colores[4]) },
                                ]}
                                background="black"
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((vigaFaltante/24)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.VigaElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.VigaElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento3, colores[2])}} />
                                    <h5>Elemento 3: {((parseFloat(calificaciones.VigaElemento3)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento4, colores[3])}} />
                                    <h5>Elemento 4: {((parseFloat(calificaciones.VigaElemento4)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.VigaRutina, colores[4])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.VigaRutina)/6)*100).toFixed()}%</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="GraficoInformeTecnico">
                    <h2>Piso</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: pisoFaltante, color: '#FF0000' },
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.PisoElemento1), color: ColorElemento(calificaciones.PisoElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.PisoElemento2), color: ColorElemento(calificaciones.PisoElemento2, colores[1]) },
                                    { title: 'Elemento 3', value: parseFloat(calificaciones.PisoElemento3), color: ColorElemento(calificaciones.PisoElemento3, colores[2]) },
                                    { title: 'Elemento 4', value: parseFloat(calificaciones.PisoElemento4), color: ColorElemento(calificaciones.PisoElemento4, colores[3]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.PisoRutina), color: ColorRutina(calificaciones.PisoRutina, colores[4]) },
                                ]}
                                background="black"
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((pisoFaltante/24)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.PisoElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.PisoElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento3, colores[2])}} />
                                    <h5>Elemento 3: {((parseFloat(calificaciones.PisoElemento3)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento4, colores[3])}} />
                                    <h5>Elemento 4: {((parseFloat(calificaciones.PisoElemento4)/4)*100).toFixed()}%</h5>
                                </div>
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.PisoRutina, colores[4])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.PisoRutina)/6)*100).toFixed()}%</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>Para pasar al siguiente nivel, se debe completar al menos el 84% del curriculo, es decir, tener un faltante menor al 16%.</h3>
            </div>}
            <button onClick={Regresar} className="button">Regresar</button>
        </section>
    )
}

export default NivelExcelBronceTecnico;