import React, { useState, useEffect } from 'react';
import "../../static/css/Nivel4.css"
import { useParams, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth'

const Nivel4 = (props) => {
    const { data } = props;
    const { auth } = useAuth();
    const navigate = useNavigate();
    const [SaltoElemento1, setSaltoElemento1] = useState("");
    const [SaltoElemento2, setSaltoElemento2] = useState("");
    const [SaltoRutina, setSaltoRutina] = useState("");
    const [BarraElemento1, setBarraElemento1] = useState("");
    const [BarraElemento2, setBarraElemento2] = useState("");
    const [BarraElemento3, setBarraElemento3] = useState("");
    const [BarraElemento4, setBarraElemento4] = useState("");
    const [BarraElemento5, setBarraElemento5] = useState("");
    const [BarraElemento6, setBarraElemento6] = useState("");
    const [BarraElemento7, setBarraElemento7] = useState("");
    const [BarraElemento8, setBarraElemento8] = useState("");
    const [BarraElemento9, setBarraElemento9] = useState("");
    const [BarraRutina, setBarraRutina] = useState("");
    const [VigaElemento1, setVigaElemento1] = useState("");
    const [VigaElemento2, setVigaElemento2] = useState("");
    const [VigaElemento3, setVigaElemento3] = useState("");
    const [VigaElemento4, setVigaElemento4] = useState("");
    const [VigaElemento5, setVigaElemento5] = useState("");
    const [VigaElemento6, setVigaElemento6] = useState("");
    const [VigaElemento7, setVigaElemento7] = useState("");
    const [VigaElemento8, setVigaElemento8] = useState("");
    const [VigaElemento9, setVigaElemento9] = useState("");
    const [VigaRutina, setVigaRutina] = useState("");
    const [PisoElemento1, setPisoElemento1] = useState("");
    const [PisoElemento2, setPisoElemento2] = useState("");
    const [PisoElemento3, setPisoElemento3] = useState("");
    const [PisoElemento4, setPisoElemento4] = useState("");
    const [PisoElemento5, setPisoElemento5] = useState("");
    const [PisoElemento6, setPisoElemento6] = useState("");
    const [PisoElemento7, setPisoElemento7] = useState("");
    const [PisoElemento8, setPisoElemento8] = useState("");
    const [PisoElemento9, setPisoElemento9] = useState("");
    const [PisoRutina, setPisoRutina] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(
            `/api/atleta/info/nivel4/`,
            JSON.stringify({
                NumSocio: data.NumSocio,
                SaltoElemento1 : SaltoElemento1,
                SaltoElemento2 : SaltoElemento2,
                SaltoRutina : SaltoRutina,
                BarraElemento1 : BarraElemento1,
                BarraElemento2 : BarraElemento2,
                BarraElemento3 : BarraElemento3,
                BarraElemento4 : BarraElemento4,
                BarraElemento5 : BarraElemento5,
                BarraElemento6 : BarraElemento6,
                BarraElemento7 : BarraElemento7,
                BarraElemento8 : BarraElemento8,
                BarraElemento9 : BarraElemento9,
                BarraRutina : BarraRutina,
                VigaElemento1 : VigaElemento1,
                VigaElemento2 : VigaElemento2,
                VigaElemento3 : VigaElemento3,
                VigaElemento4 : VigaElemento4,
                VigaElemento5 : VigaElemento5,
                VigaElemento6 : VigaElemento6,
                VigaElemento7 : VigaElemento7,
                VigaElemento8 : VigaElemento8,
                VigaElemento9 : VigaElemento9,
                VigaRutina : VigaRutina,
                PisoElemento1 : PisoElemento1,
                PisoElemento2 : PisoElemento2,
                PisoElemento3 : PisoElemento3,
                PisoElemento4 : PisoElemento4,
                PisoElemento5 : PisoElemento5,
                PisoElemento6 : PisoElemento6,
                PisoElemento7 : PisoElemento7,
                PisoElemento8 : PisoElemento8,
                PisoElemento9 : PisoElemento9,
                PisoRutina : PisoRutina,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${auth.accessToken}`,
              },
            }
          );
          alert('Informacion actualizada correctamente!');
          navigate(-1);
        } catch (err) {
          console.log(err);
        }
      };
    return(
        <section className="NivelesBajos">
            <h3 className="Categoria">Categoria: Nivel 4</h3>
             <form onSubmit={handleSubmit} className="Calificaciones">
                <h4 className="TituloGrupo">Salto</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: CARRERA DE APROXIMACION AL TRAMPOLIN</h4>
                        <input name='Elemento1Salto' type='text' className='CalificacionesInput' value={SaltoElemento1} onChange={(e) => setSaltoElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: RESORTE EN MESA DE SALTO </h4>
                        <input name='Elemento2Salto' type='text' className='CalificacionesInput' value={SaltoElemento2} onChange={(e) => setSaltoElemento2(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4>Rutina: </h4>
                        <input name='RutinaSalto' type='text' className='CalificacionesInput' value={SaltoRutina} onChange={(e) => setSaltoRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Barra</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: KIPE</h4>
                        <input name='Elemento1Barra' type='text' className='CalificacionesInput' value={BarraElemento1} onChange={(e) => setBarraElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: DESPEGUE</h4>
                        <input name='Elemento2Barra' type='text' className='CalificacionesInput' value={BarraElemento2} onChange={(e) => setBarraElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3:POLLITO/Sole circle</h4>
                        <input name='Elemento3Barra' type='text' className='CalificacionesInput' value={BarraElemento3} onChange={(e) => setBarraElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: Salto a la barra alta + KIPE</h4>
                        <input name='Elemento4Barra' type='text' className='CalificacionesInput' value={BarraElemento4} onChange={(e) => setBarraElemento4(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: Despegue a la horizontal </h4>
                        <input name='Elemento5Barra' type='text' className='CalificacionesInput' value={BarraElemento5} onChange={(e) => setBarraElemento5(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 6: Vuelta Atrás </h4>
                        <input name='Elemento6Barra' type='text' className='CalificacionesInput' value={BarraElemento6} onChange={(e) => setBarraElemento6(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 7: Balanceo canasta</h4>
                        <input name='Elemento7Barra' type='text' className='CalificacionesInput' value={BarraElemento7} onChange={(e) => setBarraElemento7(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 8: Primer balanceo / Segundo balanceo</h4>
                        <input name='Elemento8Barra' type='text' className='CalificacionesInput' value={BarraElemento8} onChange={(e) => setBarraElemento8(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 9: Salida tap-swing con 1/2 giro</h4>
                        <input name='Elemento9Barra' type='text' className='CalificacionesInput' value={BarraElemento9} onChange={(e) => setBarraElemento9(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaBarra' type='text' className='CalificacionesInput' value={BarraRutina} onChange={(e) => setBarraRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Viga</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: ENTRADA</h4>
                        <input name='Elemento1Viga' type='text' className='CalificacionesInput' value={VigaElemento1} onChange={(e) => setVigaElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: Passé + Media Luna</h4>
                        <input name='Elemento2Viga' type='text' className='CalificacionesInput' value={VigaElemento2} onChange={(e) => setVigaElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: Paso lateral, passe Relevé</h4>
                        <input name='Elemento3Viga' type='text' className='CalificacionesInput' value={VigaElemento3} onChange={(e) => setVigaElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: 1/2 giro en passe</h4>
                        <input name='Elemento4Viga' type='text' className='CalificacionesInput' value={VigaElemento4} onChange={(e) => setVigaElemento4(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: Patada cruzada  y saltos , estirado y split 120</h4>
                        <input name='Elemento5Viga' type='text' className='CalificacionesInput' value={VigaElemento5} onChange={(e) => setVigaElemento5(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 6: PARADA DE MANOS</h4>
                        <input name='Elemento6Viga' type='text' className='CalificacionesInput' value={VigaElemento6} onChange={(e) => setVigaElemento6(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 7: Giro en cucliyas y arabesque</h4>
                        <input name='Elemento7Viga' type='text' className='CalificacionesInput' value={VigaElemento7} onChange={(e) => setVigaElemento7(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 8: Gacela </h4>
                        <input name='Elemento8Viga' type='text' className='CalificacionesInput' value={VigaElemento8} onChange={(e) => setVigaElemento8(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 9: Salida</h4>
                        <input name='Elemento9Viga' type='text' className='CalificacionesInput' value={VigaElemento9} onChange={(e) => setVigaElemento9(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaViga' type='text' className='CalificacionesInput' value={VigaRutina} onChange={(e) => setVigaRutina(e.target.value)}/>
                    </div>
                </div>
                <h4 className="TituloGrupo">Piso</h4>
                <div className="Elementos">
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 1: Entrada/Arco Atrás</h4>
                        <input name='Elemento1Piso' type='text' className='CalificacionesInput' value={PisoElemento1} onChange={(e) => setPisoElemento1(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 2: Relevé Pose ,Lunge lateral,Passe adelante ,Paso lateral</h4>
                        <input name='Elemento2Piso' type='text' className='CalificacionesInput' value={PisoElemento2} onChange={(e) => setPisoElemento2(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 3: Pase lateral 1/4 de giro, lunge,lunge pose</h4>
                        <input name='Elemento3Piso' type='text' className='CalificacionesInput' value={PisoElemento3} onChange={(e) => setPisoElemento3(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 4: Resorte adelante 1 pierna, media luna quinta</h4>
                        <input name='Elemento4Piso' type='text' className='CalificacionesInput' value={PisoElemento4} onChange={(e) => setPisoElemento4(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 5: Pose , step patada, salto 1/2 giro , pose de rodilla , releve </h4>
                        <input name='Elemento5Piso' type='text' className='CalificacionesInput' value={PisoElemento5} onChange={(e) => setPisoElemento5(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 6: Passe adelante , lateral, 135 ° de giro</h4>
                        <input name='Elemento6Piso' type='text' className='CalificacionesInput' value={PisoElemento6} onChange={(e) => setPisoElemento6(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 7: Zancada , salto espagat , póse de rodillas y coreo hasta split</h4>
                        <input name='Elemento7Piso' type='text' className='CalificacionesInput' value={PisoElemento7} onChange={(e) => setPisoElemento7(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 8: Coreografia desde split Hasta firo completo y colocacion </h4>
                        <input name='Elemento8Piso' type='text' className='CalificacionesInput' value={PisoElemento8} onChange={(e) => setPisoElemento8(e.target.value)}/>
                    </div>
                    <div className="Elemento">
                        <h4 className="TituloElemento">Elemento 9: Rondada Doble filc y coreo hasta el final </h4>
                        <input name='Elemento9Piso' type='text' className='CalificacionesInput' value={PisoElemento9} onChange={(e) => setPisoElemento9(e.target.value)}/>
                    </div>
                    <div className="break"></div>
                    <div className="Rutina">
                        <h4 className="TituloElemento">Rutina: </h4>
                        <input name='RutinaPiso' type='text' className='CalificacionesInput' value={PisoRutina} onChange={(e) => setPisoRutina(e.target.value)}/>
                    </div>
                </div>
                <input type="submit" className="button" value="Actualizar" />
            </form>
        </section>
    )
}


export default Nivel4;