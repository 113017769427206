import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from "../../hooks/useAuth";
import { PieChart } from 'react-minimal-pie-chart';
import SquareIcon from '@mui/icons-material/Square';
import "../../static/css/InformeTecnicoNivelesBajos.css"
import logo from "../../static/img/4.png"


const NivelesBajosTecnico = () => {
    const NivelesBajos = ["Baby Gym", "Little Gym", "Little Star", "Beginner", "1"]
    const NivelesNumericos = ["2", "3"]
    const { auth } = useAuth();
    const param = useParams();
	const navigate = useNavigate();
    const [NumSocio, setNumSocio] = useState("");
    const [Nivel, setNivel] = useState();
	const axiosPrivate = useAxiosPrivate();
    const [atleta, setAtleta] = useState();
    const [reportes, setReportes] = useState();
    const [calificaciones, setCalificaciones] = useState();
    const [saltoFaltante, setSaltoFaltante] = useState();
    const [barraFaltante, setBarraFaltante] = useState();
    const [vigaFaltante, setVigaFaltante] = useState();
    const [pisoFaltante, setPisoFaltante] = useState();
    const [Fecha, setFecha] = useState("");
    const colores = ['#C0504D','#9BBB59','#8064A2','#4BACC6','#F79646','#516B8A','#772C2A','#5F7530','#4D3B62','#276A7C'];
    useEffect(() => {
        const decodedAccessToken = jwtDecode(auth.accessToken);
        const newNumSocio = decodedAccessToken.NumSocio || param.NumSocio;
        setNumSocio(newNumSocio);
      }, [auth.accessToken, param.NumSocio]);

    useEffect(() => {
        const getUser = async () => {
            try {
              const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}/`);
              setAtleta(response.data.data);
              setNivel(response.data.data.Nivel);
            } catch (err) {
            }
          };
        if (NumSocio){
            getUser();
        }
    }, [NumSocio, axiosPrivate]);

    useEffect(() => {
        const getCalificaciones = async () => {
          try {
            if (NivelesBajos.includes(Nivel)){
                const response = await axiosPrivate.get(`/api/atleta/info/nivelesbajos/${NumSocio}/`);
                setReportes(response.data.data);
            } else if(NivelesNumericos.includes(Nivel)){
                const response = await axiosPrivate.get(`/api/atleta/info/nivel${Nivel}/${NumSocio}/`);
                setReportes(response.data.data);
            }
          } catch (err) {
          }
        };
        if (atleta){
            getCalificaciones();
        }
    }, [atleta, axiosPrivate]);
    const Regresar = async () => {
            navigate(-1);
	}

    function ColorElemento(elemento, color){
        if(elemento === 4){
            return "#00FF00"
        }
        else{
            return color
        }
    }

    function ColorRutina(rutina, color){
        if(rutina === 6){
            return "#00FF00"
        }
        else{
            return color
        }
    }
    const handleFechaChange = (event) => {
        const selectedId = parseInt(event.target.value);
        const selectedFecha = reportes.find(reportes => reportes.id === selectedId);
        setCalificaciones(selectedFecha);
        setFecha(selectedId);
        setSaltoFaltante(14 - parseFloat(selectedFecha.SaltoElemento1) - parseFloat(selectedFecha.SaltoElemento2) - parseFloat(selectedFecha.SaltoRutina))
        setBarraFaltante(30 - parseFloat(selectedFecha.BarraElemento1) - parseFloat(selectedFecha.BarraElemento2) - parseFloat(selectedFecha.BarraElemento3) - parseFloat(selectedFecha.BarraElemento4) - parseFloat(selectedFecha.BarraElemento5)- parseFloat(selectedFecha.BarraElemento6) - parseFloat(selectedFecha.BarraRutina))
        setVigaFaltante(38 - parseFloat(selectedFecha.VigaElemento1) - parseFloat(selectedFecha.VigaElemento2) - parseFloat(selectedFecha.VigaElemento3) - parseFloat(selectedFecha.VigaElemento4) - parseFloat(selectedFecha.VigaElemento5) - parseFloat(selectedFecha.VigaElemento6) - parseFloat(selectedFecha.VigaElemento7) - parseFloat(selectedFecha.VigaElemento8) - parseFloat(selectedFecha.VigaRutina))
        setPisoFaltante(42 - parseFloat(selectedFecha.PisoElemento1) - parseFloat(selectedFecha.PisoElemento2) - parseFloat(selectedFecha.PisoElemento3) - parseFloat(selectedFecha.PisoElemento4) - parseFloat(selectedFecha.PisoElemento5) - parseFloat(selectedFecha.PisoElemento6) - parseFloat(selectedFecha.PisoElemento7) - parseFloat(selectedFecha.PisoElemento8) - parseFloat(selectedFecha.PisoElemento9) - parseFloat(selectedFecha.PisoRutina))
      };
    return(
        <section className="InformeTecnicoNivelesBajos">
            <div className="tituloylogo">
                <img className="logo" src={logo} alt="logo"/>
                {atleta && reportes && <div className="TitulosInformeTecnicoNivelesBajos">
                    <h1>Informe Técnico General</h1>
                    <h2>Atleta: {atleta.Nombre + " " + atleta.Apellido1}</h2>
                    <h2>Nivel: {Nivel}</h2>
                    <select value={Fecha} onChange={handleFechaChange} required>
                    <option value="">Selecciona una fecha</option>
                        {reportes.map((reportes, index) => (
                            <option key={`${reportes.id}-${index}`} value={reportes.id}>
                        {reportes.Fecha}
                    </option>
                    ))}
                </select>
                </div>}
            </div>
            {calificaciones && <div className="GraficosInformeTecnico">
                 <div className="FilaInformeTecnico">
                    <div className="GraficoInformeTecnico">
                        <h2>Salto</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: saltoFaltante, color: '#FF0000' , label: "Faltante"},
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.SaltoElemento1), color: ColorElemento(calificaciones.SaltoElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.SaltoElemento2), color: ColorElemento(calificaciones.SaltoElemento2, colores[1]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.SaltoRutina), color: ColorRutina(calificaciones.SaltoRutina, colores[2]) },
                                ]}
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((saltoFaltante/14)*100).toFixed()}%</h5>
                                </div>
                                <a href="https://youtube.com/shorts/la6Q5ErrAc0?feature=share">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.SaltoElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.SaltoElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtube.com/shorts/tNEfo-ZAjKk?feature=share">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.SaltoElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.SaltoElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/U-E_yU67PB4">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.SaltoRutina, colores[2])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.SaltoRutina)/6)*100).toFixed()}%</h5>
                                </div>
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className="GraficoInformeTecnico">
                        <h2>Barra</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: barraFaltante, color: '#FF0000' },
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.BarraElemento1), color: ColorElemento(calificaciones.BarraElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.BarraElemento2), color: ColorElemento(calificaciones.BarraElemento2, colores[1]) },
                                    { title: 'Elemento 3', value: parseFloat(calificaciones.BarraElemento3), color: ColorElemento(calificaciones.BarraElemento3, colores[2]) },
                                    { title: 'Elemento 4', value: parseFloat(calificaciones.BarraElemento4), color: ColorElemento(calificaciones.BarraElemento4, colores[3]) },
                                    { title: 'Elemento 5', value: parseFloat(calificaciones.BarraElemento5), color: ColorElemento(calificaciones.BarraElemento5, colores[4]) },
                                    { title: 'Elemento 6', value: parseFloat(calificaciones.BarraElemento6), color: ColorElemento(calificaciones.BarraElemento6, colores[5]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.BarraRutina), color: ColorRutina(calificaciones.BarraRutina, colores[6]) },
                                ]}
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((barraFaltante/30)*100).toFixed()}%</h5>
                                </div>
                                <a href="https://youtu.be/0-nbvdZSPTQ">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.BarraElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/jSb9R55eDtQ">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.BarraElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/mp2K932KGQY">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento3, colores[2])}} />
                                    <h5>Elemento 3: {((parseFloat(calificaciones.BarraElemento3)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/uRuPw44Kkeg">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento4, colores[3])}} />
                                    <h5>Elemento 4: {((parseFloat(calificaciones.BarraElemento4)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/P4kfwkNnnVM">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento5, colores[4])}} />
                                    <h5>Elemento 5: {((parseFloat(calificaciones.BarraElemento5)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/uRuPw44Kkeg">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.BarraElemento6, colores[5])}} />
                                    <h5>Elemento 6: {((parseFloat(calificaciones.BarraElemento6)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/yUEWMaF2zE4">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.BarraRutina, colores[6])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.BarraRutina)/6)*100).toFixed()}%</h5>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="FilaInformeTecnico">
                    <div className="GraficoInformeTecnico">
                        <h2>Viga</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: vigaFaltante, color: '#FF0000' },
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.VigaElemento1), color: ColorElemento(calificaciones.VigaElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.VigaElemento2), color: ColorElemento(calificaciones.VigaElemento2, colores[1]) },
                                    { title: 'Elemento 3', value: parseFloat(calificaciones.VigaElemento3), color: ColorElemento(calificaciones.VigaElemento3, colores[2]) },
                                    { title: 'Elemento 4', value: parseFloat(calificaciones.VigaElemento4), color: ColorElemento(calificaciones.VigaElemento4, colores[3]) },
                                    { title: 'Elemento 5', value: parseFloat(calificaciones.VigaElemento5), color: ColorElemento(calificaciones.VigaElemento5, colores[4]) },
                                    { title: 'Elemento 6', value: parseFloat(calificaciones.VigaElemento6), color: ColorElemento(calificaciones.VigaElemento6, colores[5]) },
                                    { title: 'Elemento 7', value: parseFloat(calificaciones.VigaElemento7), color: ColorElemento(calificaciones.VigaElemento7, colores[6]) },
                                    { title: 'Elemento 8', value: parseFloat(calificaciones.VigaElemento8), color: ColorElemento(calificaciones.VigaElemento8, colores[7]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.VigaRutina), color: ColorRutina(calificaciones.VigaRutina, colores[8]) },
                                ]}
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((vigaFaltante/38)*100).toFixed()}%</h5>
                                </div>
                                <a href="https://youtu.be/CwNRRI-L09Q">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.VigaElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/rNMCfJe8WiQ">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.VigaElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/0QIs66CyZYs">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento3, colores[2])}} />
                                    <h5>Elemento 3: {((parseFloat(calificaciones.VigaElemento3)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/daMbzABL3-U">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento4, colores[3])}} />
                                    <h5>Elemento 4: {((parseFloat(calificaciones.VigaElemento4)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/RpyYuvngaWU">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento5, colores[4])}} />
                                    <h5>Elemento 5: {((parseFloat(calificaciones.VigaElemento5)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/JL0mGzPpBfM">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento6, colores[5])}} />
                                    <h5>Elemento 6: {((parseFloat(calificaciones.VigaElemento6)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/56RoLTUPQys">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento7, colores[6])}} />
                                    <h5>Elemento 7: {((parseFloat(calificaciones.VigaElemento7)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/dY0qikxy-Pk">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.VigaElemento8, colores[7])}} />
                                    <h5>Elemento 8: {((parseFloat(calificaciones.VigaElemento8)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/neZRU1AcQcU">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.VigaRutina, colores[8])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.VigaRutina)/6)*100).toFixed()}%</h5>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="GraficoInformeTecnico">
                    <h2>Piso</h2>
                        <div className="GraficoYLeyendaTecnico">
                            <PieChart
                                data={[
                                    { title: 'Faltante', value: pisoFaltante, color: '#FF0000' },
                                    { title: 'Elemento 1', value: parseFloat(calificaciones.PisoElemento1), color: ColorElemento(calificaciones.PisoElemento1, colores[0]) },
                                    { title: 'Elemento 2', value: parseFloat(calificaciones.PisoElemento2), color: ColorElemento(calificaciones.PisoElemento2, colores[1]) },
                                    { title: 'Elemento 3', value: parseFloat(calificaciones.PisoElemento3), color: ColorElemento(calificaciones.PisoElemento3, colores[2]) },
                                    { title: 'Elemento 4', value: parseFloat(calificaciones.PisoElemento4), color: ColorElemento(calificaciones.PisoElemento4, colores[3]) },
                                    { title: 'Elemento 5', value: parseFloat(calificaciones.PisoElemento5), color: ColorElemento(calificaciones.PisoElemento5, colores[4]) },
                                    { title: 'Elemento 6', value: parseFloat(calificaciones.PisoElemento6), color: ColorElemento(calificaciones.PisoElemento6, colores[5]) },
                                    { title: 'Elemento 7', value: parseFloat(calificaciones.PisoElemento7), color: ColorElemento(calificaciones.PisoElemento7, colores[6]) },
                                    { title: 'Elemento 8', value: parseFloat(calificaciones.PisoElemento8), color: ColorElemento(calificaciones.PisoElemento8, colores[7]) },
                                    { title: 'Elemento 9', value: parseFloat(calificaciones.PisoElemento9), color: ColorElemento(calificaciones.PisoElemento9, colores[8]) },
                                    { title: 'Rutina', value: parseFloat(calificaciones.PisoRutina), color: ColorRutina(calificaciones.PisoRutina, colores[9]) },
                                ]}
                            />
                            <div className="Leyendas">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: "#FF0000"}} />
                                    <h5>Faltante: {((pisoFaltante/42)*100).toFixed()}%</h5>
                                </div>
                                <a href="https://youtu.be/YqcJsxp_h00">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento1, colores[0])}} />
                                    <h5>Elemento 1: {((parseFloat(calificaciones.PisoElemento1)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/ZY16M_Ao_KI">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento2, colores[1])}} />
                                    <h5>Elemento 2: {((parseFloat(calificaciones.PisoElemento2)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/NWWJVJWgC68">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento3, colores[2])}} />
                                    <h5>Elemento 3: {((parseFloat(calificaciones.PisoElemento3)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/6z_fFnkZfLQ">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento4, colores[3])}} />
                                    <h5>Elemento 4: {((parseFloat(calificaciones.PisoElemento4)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/qntZ3s8RDm4">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento5, colores[4])}} />
                                    <h5>Elemento 5: {((parseFloat(calificaciones.PisoElemento5)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/3TAyiBfqytI">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento6, colores[5])}} />
                                    <h5>Elemento 6: {((parseFloat(calificaciones.PisoElemento6)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/KLFOREy-Wvs">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento7, colores[6])}} />
                                    <h5>Elemento 7: {((parseFloat(calificaciones.PisoElemento7)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/0_lPSaP1svM">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento8, colores[7])}} />
                                    <h5>Elemento 8: {((parseFloat(calificaciones.PisoElemento8)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/Hz9Cmz0eaZU">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorElemento(calificaciones.PisoElemento9, colores[8])}} />
                                    <h5>Elemento 9: {((parseFloat(calificaciones.PisoElemento9)/4)*100).toFixed()}%</h5>
                                </div>
                                </a>
                                <a href="https://youtu.be/PB9DIMIeEXE">
                                <div className="Leyenda">
                                    <SquareIcon style={{color: ColorRutina(calificaciones.PisoRutina, colores[9])}} />
                                    <h5>Rutina: {((parseFloat(calificaciones.PisoRutina)/6)*100).toFixed()}%</h5>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                NivelesBajos.includes(Nivel)?
                <h3>Para pasar al siguiente nivel, se debe completar al menos el 70% del curriculo, es decir, tener un faltante menor al 30%.</h3>
                : Nivel=== "2"?
                <h3>Para pasar al nivel 3, se debe completar al menos el 90% del curriculo, es decir, tener un faltante menor al 10%.</h3>
                :<h3>Para pasar al nivel 4, se debe completar al menos el 90% del curriculo, es decir, tener un faltante menor al 10%.</h3>
                }
            </div>}
            <button onClick={Regresar} className="button">Regresar</button>
        </section>
    )
}

export default NivelesBajosTecnico;