import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from "../../hooks/useAuth";
import "../../static/css/InformeFisico.css"
import { GraficoFuerza, GraficoFlexibilidad } from "../../components/Atletas/GraficosInformeFisico";
import logo from "../../static/img/4.png"

const InformeFisico = () =>{
    const { auth } = useAuth();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
    const param = useParams();
    const [atleta, setAtleta] = useState();
    const [NumSocio, setNumSocio] = useState("");
    const [Nivel, setNivel] = useState();
    useEffect(() => {
        const decodedAccessToken = jwtDecode(auth.accessToken);
        const newNumSocio = decodedAccessToken.NumSocio || param.NumSocio;
        setNumSocio(newNumSocio);
      }, [auth.accessToken, param.NumSocio]);

    
    useEffect(() => {
        const getUser = async () => {
            try {
              const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}/`);
              setAtleta(response.data.data);
              setNivel(response.data.data.Nivel)
            } catch (err) {
            }
          };
        if(NumSocio){
            getUser();
        }
    }, [NumSocio, axiosPrivate]);
    const Regresar = async () => {
		navigate(-1)
	}
    
    return(
        <section className="InformeFisico">
            <div className="tituloylogo">
                <img className="logo" src={logo} alt="logo"/>
                {atleta && <div className="TitulosInformeTecnicoNivelesBajos">
                <h1>Informe Físico General</h1>
                <h2>Atleta: {atleta.Nombre + " " + atleta.Apellido1}</h2>
                {Nivel === "ExcelBronce"?
                <h2>Nivel: Excel Bronce</h2>
                :Nivel === "ExcelSilver"?
                <h2>Nivel: Excel Silver</h2>
                :Nivel === "ExcelGold"?
                <h2>Nivel: Excel Gold</h2>
                :<h2>Nivel: {Nivel}</h2>
                }
            </div>}
            </div>
            <div className="GraficosTestFisico">
                <div className="GraficoYTitulo">
                    <h3>Flexibilidad</h3>
                    <GraficoFlexibilidad NumSocio={NumSocio} />
                </div>
                <div className="GraficoYTitulo">
                    <h3>Fuerza</h3>
                    <GraficoFuerza NumSocio={NumSocio} />
                </div>
            </div>
            <button onClick={Regresar} className="button">Regresar</button>
        </section>
    )
}

export default InformeFisico;