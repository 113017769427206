import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/Admin/Sidebar';
import '../../static/css/InfoDetailed.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth';
import SidebarSede from '../../components/Sede/SidebarSede';
import jwtDecode from 'jwt-decode';

const DetailedInfo = () => {
  const [atleta, setAtleta] = useState();
	const axiosPrivate = useAxiosPrivate();
  const { NumSocio } = useParams();
  const { auth } = useAuth();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}`);
        setAtleta(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    getUser();
  }, [axiosPrivate, NumSocio]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `/api/atleta/info/${NumSocio}/`,
        JSON.stringify({
          Nombre: atleta.Nombre,
          Apellido1: atleta.Apellido1,
          Apellido2: atleta.Apellido2,
          Email: atleta.Email,
          DOB: atleta.DOB,
          Nivel: atleta.Nivel,
          Modalidad: atleta.Modalidad,
          Centro: atleta.Centro,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${auth.accessToken}`,
          },
        }
      );
      alert('Informacion actualizada correctamente!');
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className='InfoDetailed'>
      { jwtDecode(auth.accessToken).role === 1200
      ?<SidebarSede />
      :<Sidebar />
      }
      {atleta && <div className='InfoEditContent'>
        <h1>Editar informacion del usuario</h1>
        <form onSubmit={handleSubmit} className="EditForm">
          <label htmlFor="Nombre">Nombre:</label>
          <input name='Nombre' type='text' className='text-field' placeholder='Nombre' value={atleta.Nombre} onChange={(e) => setAtleta({ ...atleta, Nombre: e.target.value })}/>
          <label htmlFor="Apellido1">Apellido:</label>
          <input name='Apellido1' type='text' className='text-field' placeholder='Apellido' value={atleta.Apellido1} onChange={(e) => setAtleta({ ...atleta, Apellido1: e.target.value })}/>
          <label htmlFor="Apellido2">Segundo Apellido:</label>
          <input name='Apellido2' type='text' className='text-field' placeholder='Segundo Apellido' value={atleta.Apellido2} onChange={(e) => setAtleta({ ...atleta, Apellido2: e.target.value })}/>
          <label htmlFor="Email">Email:</label>
          <input name='Email' type='email' className='text-field' placeholder='Email' value={atleta.Email} onChange={(e) => setAtleta({ ...atleta, Email: e.target.value })}/>
          {/* Selector de Nivel */}
          <input type="submit" className="button" value="Actualizar" />
        </form>
        <div className='botones'>
				  <a href={`/admin/test-tecnico/${NumSocio}`}><button className="button">TEST TECNICO</button></a>
				  <a href={`/admin/informe-tecnico/${NumSocio}`}><button className="button">INFORME TECNICO</button></a>
				  <a href={`/admin/test-fisico/${NumSocio}`}><button className="button">TEST FISICO</button></a>
				  <a href={`/admin/informe-fisico/${NumSocio}`}><button className="button">INFORME FISICO</button></a>
				  <a href={`/admin/agregar-resultado/${NumSocio}`}><button className="button">RANKINGS</button></a>
        </div>
        </div>

      }
    </section>
  );
};

export default DetailedInfo;