import { useState, useEffect } from "react";
import "../static/css/Rankings.css"
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import useAuth from "../hooks/useAuth";

const Rankings = () =>{
    const options = [
        { value: '', text: 'Nivel' },
        { value: 'nivelesbajos', text: 'Niveles Bajos' },
        { value: '1', text: 'Nivel 1' },
        { value: '2', text: 'Nivel 2' },
        { value: '3', text: 'Nivel 3' },
        { value: '4', text: 'Nivel 4' },
        { value: 'nivelexcel', text: 'Nivel Excel' },
      ];
    const [selected, setSelected] = useState(options[0].value);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selection, setSelection] = useState([]);
    const [nivelesBajos, setNivelesBajos] = useState([]);
    const [nivel1, setNivel1] = useState([]);
    const [nivel2, setNivel2] = useState([]);
    const [nivel3, setNivel3] = useState([]);
    const [nivel4, setNivel4] = useState([]);
    const [nivelExcel, setNivelExcel] = useState([]);
    const [medallero, setMedallero] = useState([]);
    const [medalleroTemp, setMedalleroTemp] = useState([]);
    const { auth } = useAuth();
	const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const role = jwtDecode(auth.accessToken).role
    const NivelesBajos = ["Baby Gym", "Little Gym", "Little Star", "Beginner"]
    const NivelesExcel = ["ExcelBronce", "ExcelSilver", "ExcelGold"]



    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getUsers = async () => {
          try {
            const nivelesbajosresponse = await axiosPrivate.get('/api/atleta/resultados/nivelesbajos/', {
              signal: controller.signal
            });
            isMounted && setNivelesBajos(nivelesbajosresponse.data.data);
            const nivel1response = await axiosPrivate.get('/api/atleta/resultados/nivel1/', {
                signal: controller.signal
              });
              isMounted && setNivel1(nivel1response.data.data);
            const nivel2response = await axiosPrivate.get('/api/atleta/resultados/nivel2/', {
                signal: controller.signal
             });
            isMounted && setNivel2(nivel2response.data.data);
            const nivel3response = await axiosPrivate.get('/api/atleta/resultados/nivel3/', {
                signal: controller.signal
              });
              isMounted && setNivel3(nivel3response.data.data);
              const nivel4response = await axiosPrivate.get('/api/atleta/resultados/nivel4/', {
                signal: controller.signal
              });
              isMounted && setNivel4(nivel4response.data.data);
            const nivelexcelresponse = await axiosPrivate.get('/api/atleta/resultados/nivelexcel/', {
                signal: controller.signal
              });
              isMounted && setNivelExcel(nivelexcelresponse.data.data);
            const medalleroresponse = await axiosPrivate.get('/api/atleta/medallero/', {
                signal: controller.signal
              });
              isMounted && setMedalleroTemp(medalleroresponse.data.data);
            
          } catch (err) {
            console.error(err);
          }
        }
        getUsers();
        return () => {
          isMounted = false;
          controller.abort();
        }
      }, [axiosPrivate])

      const Regresar = async () => {
        if(role === 1000){
            navigate('/')
        }
        else if(role===1100){
            navigate('/admin')
        } else if(role===1200){
            navigate('/admin-sede')
        }

	}
      
      const handleChange = (event) => {
        const value = event.target.value;
        setSelected(value);
        let tempArray = [];
        let medalleroTemporal = [];
      
        if (value === "") {
          setSelection([]);
          setIsLoaded(false);
        } else if(value === "nivelesbajos"){
          tempArray = nivelesBajos;
          setSelection(tempArray);
          setIsLoaded(true);
          for(let i = 0; i < medalleroTemp.length; i++){
            if(NivelesBajos.includes(medalleroTemp[i].Nivel)){
              medalleroTemporal.push(medalleroTemp[i]);
            }
          }
          setMedallero(medalleroTemporal)
        } else if(value === "1"){
            tempArray = nivel1
            setSelection(tempArray);
            setIsLoaded(true);
            for(let i = 0; i < medalleroTemp.length; i++){
              if(medalleroTemp[i].Nivel === "1"){
                medalleroTemporal.push(medalleroTemp[i]);
              }
            }
            setMedallero(medalleroTemporal)
        } else if(value === "2"){
            tempArray = nivel2
            setSelection(tempArray);
            setIsLoaded(true);
            for(let i = 0; i < medalleroTemp.length; i++){
              if(medalleroTemp[i].Nivel === "2"){
                medalleroTemporal.push(medalleroTemp[i]);
              }
            }
            setMedallero(medalleroTemporal)
        } else if(value === "3"){
            tempArray = nivel3
            setSelection(tempArray);
            setIsLoaded(true);
            for(let i = 0; i < medalleroTemp.length; i++){
              if(medalleroTemp[i].Nivel === "3"){
                medalleroTemporal.push(medalleroTemp[i]);
              }
            }
            setMedallero(medalleroTemporal)
        } else if(value === "4"){
            tempArray = nivel4
            setSelection(tempArray);
            setIsLoaded(true);
            for(let i = 0; i < medalleroTemp.length; i++){
              if(medalleroTemp[i].Nivel === "4"){
                medalleroTemporal.push(medalleroTemp[i]);
              }
            }
            setMedallero(medalleroTemporal)
        } else if(value === "nivelexcel"){
            tempArray = nivelExcel;
            setSelection(tempArray);
            setIsLoaded(true);
            for(let i = 0; i < medalleroTemp.length; i++){
              if(NivelesExcel.includes(medalleroTemp[i].Nivel)){
                medalleroTemporal.push(medalleroTemp[i]);
              }
            }
            setMedallero(medalleroTemporal)
          }
      };
      const getPuestoColorClass = (puesto) => {
        if (puesto === 1) {
          return "gold";
        } else if (puesto === 2) {
          return "silver";
        } else if (puesto === 3) {
          return "bronze";
        }
        return "";
      };
    
      const isHighestValue = (value, columnData) => {
        const max = Math.max(...columnData);
        return value === max;
      };

    return(
        <section className="rankings">
            <header className="titulo">
                <h1>Rankings</h1>
                <h3>Categoría:</h3>
                <select value={selected} onChange={handleChange}>
			        {options.map((option) => (
			            <option key={option.value} value={option.value}>
			        	    {option.text}
			            </option>
			        ))}
		        </select>
            </header>
            {isLoaded && selection.length > 0 ?  <div className="tablas">
                <div className="table-title">Cuadro de Resultados</div>
                <table className="resultados">
                    <thead>
                        <tr>
                            <th>Gimnasta</th>
                            <th>Competencia</th>
                            <th>Salto</th>
                            <th>Puesto</th>
                            <th>Barra</th>
                            <th>Puesto</th>
                            <th>Viga</th>
                            <th>Puesto</th>
                            <th>Piso</th>
                            <th>Puesto</th>
                            <th>AA</th>
                            <th>Puesto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selection.map((item)=>(
                            <tr key={item.id}>
                                <td>{item.Gimnasta}</td>
                                <td>{item.Competencia}</td>
                                <td className={isHighestValue(item.PuntajeSalto, selection.map((item) => item.PuntajeSalto)) ? 'green' : ''}>
                                    {item.PuntajeSalto}
                                </td>
                                <td className={getPuestoColorClass(item.PuestoSalto)}>
                                    {item.PuestoSalto}
                                </td>
                                <td className={isHighestValue(item.PuntajeBarra, selection.map((item) => item.PuntajeBarra)) ? 'green' : ''}>
                                    {item.PuntajeBarra}
                                </td>
                                <td className={getPuestoColorClass(item.PuestoBarra)}>
                                    {item.PuestoBarra}
                                </td>
                                <td className={isHighestValue(item.PuntajeBarra, selection.map((item) => item.PuntajeBarra)) ? 'green' : ''}>
                                    {item.PuntajeBarra}
                                </td>
                                <td className={getPuestoColorClass(item.PuestoViga)}>
                                    {item.PuestoViga}
                                </td>
                                <td className={isHighestValue(item.PuntajePiso, selection.map((item) => item.PuntajePiso)) ? 'green' : ''}>
                                    {item.PuntajePiso}
                                </td>
                                <td className={getPuestoColorClass(item.PuestoPiso)}>
                                    {item.PuestoPiso}
                                </td>
                                <td className={isHighestValue(item.PuntajeAA, selection.map((item) => item.PuntajeAA)) ? 'green' : ''}>
                                    {item.PuntajeAA}
                                </td>
                                <td className={getPuestoColorClass(item.PuestoAA)}>
                                    {item.PuestoAA}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="table-title">Medallero Provisional Internacional</div>
                <table className="resultados">
                    <thead>
                        <tr>
                            <th>Gimnasta</th>
                            <th>Oro</th>
                            <th>Plata</th>
                            <th>Bronce</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {medallero.map((item)=>(
                            <tr key={item.id}>
                                <td>{item.Gimnasta}</td>
                                <td>{item.Oro}</td>
                                <td>{item.Plata}</td>
                                <td>{item.Bronce}</td>
                                <td>{item.Oro + item.Plata + item.Bronce}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            : (
                <p>No hay datos para mostrar.</p>
              )}
            <button onClick={Regresar} className="button">Regresar</button>
        </section>
    );
}
export default Rankings;