import {React} from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './pages/Unauthorized';
import PersistLogin from "./components/PersistLogin"
import InformacionAtletas from './pages/Admin/InformacionAtletas';
import Login from './pages/auth/Login'
import HomeAtletas from './pages/Atletas/HomeAtletas';
import HomeAdmin from './pages/Admin/HomeAdmin';
import DetailedInfo from './pages/Admin/DetailedInfo';
import AgregarAtleta from './pages/Admin/AgregarAtleta';
import AgregarAdministrador from './pages/Admin/AgregarAdministrador';
import ChangePassword from './pages/auth/ChangePassword';
import TestTecnico from './pages/Admin/TestTecnico';
import TestFisico from './pages/Admin/TestFisico';
import InformeTecnico from './pages/Atletas/InformeTecnico';
import InformeFisico from './pages/Atletas/InformeFisico';
import ImportarAtletas from './pages/Sede/ImportarAtletas';
import AgregarAdministradorCentro from './pages/Sede/AgregarAdministradorCentro';
import AgregarResultado from './pages/Admin/AgregarResultado';
import Rankings from './pages/Rankings';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Publico */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* Atletas */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[1000]}/>}>
            <Route path="/" element={<HomeAtletas />} />
            <Route path="/informe-tecnico" element={<InformeTecnico />} />
            <Route path="/informe-fisico" element={<InformeFisico />} />
          </Route>
          {/* Entrenadores */}
          <Route path="admin" element={<RequireAuth allowedRoles={[1100, 1200]}/> }>
            <Route path="" element={<HomeAdmin />} />
            <Route path="info-atletas" element={<InformacionAtletas />} />
            <Route path="info-atletas/:NumSocio" element={<DetailedInfo />} />
            <Route path='agregar-atleta' element={<AgregarAtleta />} />
            <Route path='agregar-admin' element={<AgregarAdministrador />} />
            <Route path='test-tecnico/:NumSocio' element={<TestTecnico />} />
            <Route path='test-fisico/:NumSocio' element={<TestFisico />} />
            <Route path='agregar-resultado/:NumSocio' element={<AgregarResultado />} />
            <Route path="informe-tecnico/:NumSocio" element={<InformeTecnico />} />
            <Route path="informe-fisico/:NumSocio" element={<InformeFisico />} />
          </Route>
          {/* Administrador de Sede */}
          <Route path="admin-sede" element={<RequireAuth allowedRoles={[1200]}/>}>
            <Route path="" element={<HomeAdmin />} />
            <Route path="importar-atletas" element={<ImportarAtletas />} />
            <Route path='agregar-centro' element={<AgregarAdministradorCentro />} />
          </Route>
          {/* Cambio de contrasena */}
          <Route element={<RequireAuth allowedRoles={[1200, 1100, 1000]}/>}>
            <Route path="change-password" element={<ChangePassword />} />
            <Route path='rankings' element={<Rankings />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;