// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestFisico{
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.Titulo{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.TestFisicoContent{
    width: 100%;
}
.CalificacionesFisico{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.CalificacionesInput{
    margin-right: 1vh;
}
.ElementosFisico{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/static/css/TestFisico.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".TestFisico{\n    display: flex;\n    flex-direction: row;\n    height: 100vh;\n}\n.Titulo{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    margin-left: auto;\n    margin-right: auto;\n}\n.TestFisicoContent{\n    width: 100%;\n}\n.CalificacionesFisico{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.CalificacionesInput{\n    margin-right: 1vh;\n}\n.ElementosFisico{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
