import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from '../../components/Admin/Sidebar';
import "../../static/css/TestFisico.css"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import jwtDecode from "jwt-decode";
import SidebarSede from "../../components/Sede/SidebarSede";

const TestFisico = () => {
    const NivelesNumericos = ["1", "2", "3", "4", "5", "6", "7"]
    const [atleta, setAtleta] = useState();
    const [calificaciones, setCalificaciones] = useState();
	const axiosPrivate = useAxiosPrivate();
    const { NumSocio } = useParams();
    const { auth } = useAuth();
    const [Nivel, setNivel] = useState("");

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        
        const getUsers = async () => {
            try{
                const response = await axiosPrivate.get(`/api/atleta/info/${NumSocio}`, {
                    signal: controller.signal
                });
                isMounted && setAtleta(response.data.data);
                if(NivelesNumericos.includes(response.data.data.Nivel)){
                    setNivel("Nivel " + response.data.data.Nivel)
                  }else{setNivel(response.data.data.Nivel)}
            } catch(err) {
                console.error(err);
            }
        }
        getUsers();
        const getCalificaciones = async () => {
            try {
              const response = await axiosPrivate.get(`/api/atleta/info/testfisico/${NumSocio}`);
              setCalificaciones(response.data.data);
              if(NivelesNumericos.includes(response.data.data.Nivel)){
                setNivel("Nivel " + response.data.data.Nivel)
              }
            } catch (err) {
              console.error(err);
            }
          };
          getCalificaciones();
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [NumSocio, axiosPrivate])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.patch(
            `/api/atleta/info/testfisico/${NumSocio}/`,
            JSON.stringify({
              Flexibilidad1 : calificaciones.Flexibilidad1,
              Flexibilidad2 : calificaciones.Flexibilidad2,
              Flexibilidad3 : calificaciones.Flexibilidad3,
              Flexibilidad4 : calificaciones.Flexibilidad4,
              Flexibilidad5 : calificaciones.Flexibilidad5,
              Flexibilidad6 : calificaciones.Flexibilidad6,
              Fuerza1 : calificaciones.Fuerza1,
              Fuerza2 : calificaciones.Fuerza2,
              Fuerza3 : calificaciones.Fuerza3,
              Fuerza4 : calificaciones.Fuerza4,
              Fuerza5 : calificaciones.Fuerza5,
              Fuerza6 : calificaciones.Fuerza6,
              Fuerza7 : calificaciones.Fuerza7,
              Fuerza8 : calificaciones.Fuerza8,
              Fuerza9 : calificaciones.Fuerza9,
              Fuerza10 : calificaciones.Fuerza10,
              Fuerza11 : calificaciones.Fuerza10,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${auth.accessToken}`,
              },
            }
          );
          alert('Informacion actualizada correctamente!');
        } catch (err) {
        }
      };


    return (
        <section className="TestFisico">
            { jwtDecode(auth.accessToken).role === 1200
                ?<SidebarSede />
                :<Sidebar />
            }
            {atleta && <div className="TestFisicoContent">
                <div className="Titulo">
                    <h1 className="TestFisicoTitulo">Test Fisico</h1>
                    <h3 className="NombreGimnasta">Gimnasta: {atleta.Nombre + " " + atleta.Apellido1}</h3>
                    <h3 className="NombreGimnasta">Categoria: {Nivel}</h3>
                </div>
                {calificaciones && <form onSubmit={handleSubmit} className="CalificacionesFisico">
                    <div className="ElementosFisico">
                        <div className="ElementoFisico">
                            <h4 className="TituloGrupo">Flexibilidad</h4>
                            <div className="Elemento">
                                <input name='Flexibilidad1' type='text' className='CalificacionesInput' value={calificaciones.Flexibilidad1} onChange={(e) => setCalificaciones({ ...calificaciones, Flexibilidad1: e.target.value })}/>
                                <h4 className="TituloElementoFisico">SPLIT ANTERO - POSTERIOR</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Flexibilidad2' type='text' className='CalificacionesInput' value={calificaciones.Flexibilidad2} onChange={(e) => setCalificaciones({ ...calificaciones, Flexibilidad2: e.target.value })}/>
                                <h4 className="TituloElementoFisico">SPLIT LATERAL (spagat frontal )</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Flexibilidad3' type='text' className='CalificacionesInput' value={calificaciones.Flexibilidad3} onChange={(e) => setCalificaciones({ ...calificaciones, Flexibilidad3: e.target.value })}/>
                                <h4 className="TituloElementoFisico">Arco</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Flexibilidad4' type='text' className='CalificacionesInput' value={calificaciones.Flexibilidad4} onChange={(e) => setCalificaciones({ ...calificaciones, Flexibilidad4: e.target.value })}/>
                                <h4 className="TituloElementoFisico">FLEXION TRONCO AL FRENTE</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Flexibilidad5' type='text' className='CalificacionesInput' value={calificaciones.Flexibilidad5} onChange={(e) => setCalificaciones({ ...calificaciones, Flexibilidad5: e.target.value })}/>
                                <h4 className="TituloElementoFisico">FLEXIBILIDAD DE HOMBROS</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Flexibilidad6' type='text' className='CalificacionesInput' value={calificaciones.Flexibilidad6} onChange={(e) => setCalificaciones({ ...calificaciones, Flexibilidad6: e.target.value })}/>
                                <h4 className="TituloElementoFisico">FLEXION TRONCO AL FRENTE SENTADO CON PIERNAS SEPARADAS</h4>
                            </div>
                        </div>
                        <div className="ElementoFisico">
                            <h4 className="TituloGrupo">Fuerza</h4>
                            <div className="Elemento">
                                <input name='Fuerza1' type='text' className='CalificacionesInput' value={calificaciones.Fuerza1} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza1: e.target.value })}/>
                                <h4 className="TituloElementoFisico">SALTO HORIZONTAL</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza2' type='text' className='CalificacionesInput' value={calificaciones.Fuerza2} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza2: e.target.value })}/>
                                <h4 className="TituloElementoFisico">VELOCIDAD</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza3' type='text' className='CalificacionesInput' value={calificaciones.Fuerza3} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza3: e.target.value })}/>
                                <h4 className="TituloElementoFisico">ELEVACION DE PIERNAS DESDE LA SUSPENSION ( ESCUADRAS) A 90º</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza4' type='text' className='CalificacionesInput' value={calificaciones.Fuerza4} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza4: e.target.value })}/>
                                <h4 className="TituloElementoFisico">ELEVACION DE TRONCO AL FRENTE</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza5' type='text' className='CalificacionesInput' value={calificaciones.Fuerza5} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza5: e.target.value })}/>
                                <h4 className="TituloElementoFisico">BARBILLA A LA BARRA MANTENER BICEPS</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza6' type='text' className='CalificacionesInput' value={calificaciones.Fuerza6} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza6: e.target.value })}/>
                                <h4 className="TituloElementoFisico">FONDOS BARRA</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza7' type='text' className='CalificacionesInput' value={calificaciones.Fuerza7} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza7: e.target.value })}/>
                                <h4 className="TituloElementoFisico">SOSTENER PARADA DE MANOS LONGITUDINAL EN VIGA BAJA</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza8' type='text' className='CalificacionesInput' value={calificaciones.Fuerza8} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza8: e.target.value })}/>
                                <h4 className="TituloElementoFisico">SALTO VERTICAL</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza9' type='text' className='CalificacionesInput' value={calificaciones.Fuerza9} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza9: e.target.value })}/>
                                <h4 className="TituloElementoFisico">SUBIDAS A FUERZA EN BARRA</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza10' type='text' className='CalificacionesInput' value={calificaciones.Fuerza10} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza10: e.target.value })}/>
                                <h4 className="TituloElementoFisico">OLIMPICOS EN VIGA</h4>
                            </div>
                            <div className="Elemento">
                                <input name='Fuerza11' type='text' className='CalificacionesInput' value={calificaciones.Fuerza11} onChange={(e) => setCalificaciones({ ...calificaciones, Fuerza11: e.target.value })}/>
                                <h4 className="TituloElementoFisico">IMPULSO ATRAS A PARADA DE MANOS ( VERTICAL) EN BARRA</h4>
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="button" value="Actualizar" />
                </form>
            }
            </div>
            }
        </section>
    )
}

export default TestFisico;