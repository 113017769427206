import React, { useState, useEffect } from "react";
import "../../static/css/Nivel4.css";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const DecimoAno = () => {
  const axiosPrivate = useAxiosPrivate();
  const { NumSocio } = useParams();
  const [Elemento1, setElemento1] = useState("");
  const [Elemento2, setElemento2] = useState("");
  const [Elemento3, setElemento3] = useState("");
  const [Elemento4, setElemento4] = useState("");
  const [Elemento5, setElemento5] = useState("");
  const [Elemento6, setElemento6] = useState("");
  const [Elemento7, setElemento7] = useState("");
  const [Elemento8, setElemento8] = useState("");
  const [Elemento9, setElemento9] = useState("");
  const [Elemento10, setElemento10] = useState("");
  const [Elemento11, setElemento11] = useState("");
  const [Elemento12, setElemento12] = useState("");
  const [Elemento13, setElemento13] = useState("");
  const [Elemento14, setElemento14] = useState("");
  const [Elemento15, setElemento15] = useState("");
  const [Elemento16, setElemento16] = useState("");
  const [Elemento17, setElemento17] = useState("");
  const [Elemento18, setElemento18] = useState("");
  const [Elemento19, setElemento19] = useState("");
  const [Elemento20, setElemento20] = useState("");
  const [Elemento21, setElemento21] = useState("");
  const [Elemento22, setElemento22] = useState("");
  const [Elemento23, setElemento23] = useState("");
  const [Elemento24, setElemento24] = useState("");
  const [Elemento25, setElemento25] = useState("");
  const [Elemento26, setElemento26] = useState("");
  const [Elemento27, setElemento27] = useState("");
  const [Elemento28, setElemento28] = useState("");
  const [Elemento29, setElemento29] = useState("");
  const [Elemento30, setElemento30] = useState("");
  const [Elemento31, setElemento31] = useState("");
  const [Elemento32, setElemento32] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosPrivate.post(
        `/api/atleta/info/trampolin/`,
        JSON.stringify({
          NumSocio: NumSocio,
          Elemento1: Elemento1,
          Elemento2: Elemento2,
          Elemento3: Elemento3,
          Elemento4: Elemento4,
          Elemento5: Elemento5,
          Elemento6: Elemento6,
          Elemento7: Elemento7,
          Elemento8: Elemento8,
          Elemento9: Elemento9,
          Elemento10: Elemento10,
          Elemento11: Elemento11,
          Elemento12: Elemento12,
          Elemento13: Elemento13,
          Elemento14: Elemento14,
          Elemento15: Elemento15,
          Elemento16: Elemento16,
          Elemento17: Elemento17,
          Elemento18: Elemento18,
          Elemento19: Elemento19,
          Elemento20: Elemento20,
          Elemento21: Elemento21,
          Elemento22: Elemento22,
          Elemento23: Elemento23,
          Elemento24: Elemento24,
          Elemento25: Elemento25,
          Elemento26: Elemento26,
          Elemento27: Elemento27,
          Elemento28: Elemento28,
          Elemento29: Elemento29,
          Elemento30: Elemento30,
          Elemento31: Elemento31,
          Elemento32: Elemento32,
        })
      );
      alert("Informacion actualizada correctamente!");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className="Nivel4">
      <h3 className="Categoria">Deporte: Trampolin</h3>
      <h3 className="Categoria">Categoria: Decimo Año</h3>
      <form onSubmit={handleSubmit} className="Calificaciones">
        <div className="Elementos">
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 1: Espalda Pie</h4>
            <input
              name="Elemento1Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento1}
              onChange={(e) => setElemento1(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 2: Back O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento2}
              onChange={(e) => setElemento2(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 3: Front O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento3}
              onChange={(e) => setElemento3(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 4: Front {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento4}
              onChange={(e) => setElemento4(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 5: Back /</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento5}
              onChange={(e) => setElemento5(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 6: Barany /</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento6}
              onChange={(e) => setElemento6(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 7: Barany O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento7}
              onChange={(e) => setElemento7(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 8: Barany {"<"} /</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento8}
              onChange={(e) => setElemento8(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 9: Back {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento9}
              onChange={(e) => setElemento9(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 10: 3/4 AT</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento10}
              onChange={(e) => setElemento10(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 11: 3/4 AD</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento11}
              onChange={(e) => setElemento11(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 12: CODY</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento12}
              onChange={(e) => setElemento12(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 13: BABY</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento13}
              onChange={(e) => setElemento13(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 14: Full Back</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento14}
              onChange={(e) => setElemento14(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 15: Rudy</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento15}
              onChange={(e) => setElemento15(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 16: 8 - - 1 O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento16}
              onChange={(e) => setElemento16(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 17: 7 - - O{"/<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento17}
              onChange={(e) => setElemento17(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 18: 8 - - O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento18}
              onChange={(e) => setElemento18(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 19: 8 - - {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento19}
              onChange={(e) => setElemento19(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 20: 8 - - 1 {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento20}
              onChange={(e) => setElemento20(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 21: Rudy ball out</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento21}
              onChange={(e) => setElemento21(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 22: 8 1 1 O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento22}
              onChange={(e) => setElemento22(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 23: 8 2 1O/ 8-3O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento23}
              onChange={(e) => setElemento23(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 24: 8 - - /</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento24}
              onChange={(e) => setElemento24(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 25: 8 2 1 {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento25}
              onChange={(e) => setElemento25(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 26: 8 - 3 {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento26}
              onChange={(e) => setElemento26(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 27: 8 1 1 {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento27}
              onChange={(e) => setElemento27(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 28: 8 3 1 O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento28}
              onChange={(e) => setElemento28(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 29: 8 3 1 {"<"}</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento29}
              onChange={(e) => setElemento29(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 30: 8 1 1 /</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento30}
              onChange={(e) => setElemento30(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 31: 12 - - 1 O</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento31}
              onChange={(e) => setElemento31(e.target.value)}
            />
          </div>
          <div className="Elemento">
            <h4 className="TituloElemento">Elemento 32: 8 2 2 /</h4>
            <input
              name="Elemento2Salto"
              type="text"
              className="CalificacionesInput"
              value={Elemento32}
              onChange={(e) => setElemento32(e.target.value)}
            />
          </div>
        </div>
        <input type="submit" className="button" value="Actualizar" />
      </form>
    </section>
  );
};

export default DecimoAno;
