// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InformacionAtletas{
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}
.Sidebar{
    position: fixed;
}
.Selector{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.AtletasContent{
    margin-top: 5vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.InformacionAtletas .AtletasContent table {
    border-collapse: collapse;
    margin-bottom: 5vh;
    font-size: 2vh;
}

.InformacionAtletas .AtletasContent th ,
  .InformacionAtletas .AtletasContent td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}
.InformacionAtletas .AtletasContent th {
    background-color: #f9f9f9;
    text-align: center;
}

.InformacionAtletas .AtletasContent tbody tr{
    background-color: #f9f9f9;
}
  
  .InformacionAtletas .AtletasContent tbody tr:hover {
    background-color: #f9f9f9;
}
.InfoBoxes{
    margin-right: 1vh;
    margin-left: 1vh;
}

.InformacionAtletas .button {
    color: #fff;
    margin: 0.2rem;
    padding: 0.1em 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
    background: #449dd5;
    border: 0.05rem solid #449dd5;
  }`, "",{"version":3,"sources":["webpack://./src/static/css/InformacionAtletas.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;;IAEI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;EAEE;IACE,yBAAyB;AAC7B;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;IACf,mBAAmB;IACnB,6BAA6B;EAC/B","sourcesContent":[".InformacionAtletas{\n    display: flex;\n    flex-direction: row;\n    min-height: 100vh;\n}\n.Sidebar{\n    position: fixed;\n}\n.Selector{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.AtletasContent{\n    margin-top: 5vh;\n    margin-left: auto;\n    margin-right: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;    \n}\n.InformacionAtletas .AtletasContent table {\n    border-collapse: collapse;\n    margin-bottom: 5vh;\n    font-size: 2vh;\n}\n\n.InformacionAtletas .AtletasContent th ,\n  .InformacionAtletas .AtletasContent td {\n    border: 1px solid black;\n    padding: 8px;\n    text-align: center;\n}\n.InformacionAtletas .AtletasContent th {\n    background-color: #f9f9f9;\n    text-align: center;\n}\n\n.InformacionAtletas .AtletasContent tbody tr{\n    background-color: #f9f9f9;\n}\n  \n  .InformacionAtletas .AtletasContent tbody tr:hover {\n    background-color: #f9f9f9;\n}\n.InfoBoxes{\n    margin-right: 1vh;\n    margin-left: 1vh;\n}\n\n.InformacionAtletas .button {\n    color: #fff;\n    margin: 0.2rem;\n    padding: 0.1em 0.5rem;\n    font-size: 1.2rem;\n    cursor: pointer;\n    background: #449dd5;\n    border: 0.05rem solid #449dd5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
